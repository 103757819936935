<template>
  <div class="row mt-2" v-if="type === 'list' || type === 'group'">
    <!-- is group -->
    <div
      :open="openListItem || isNewListItem()"
      class="card col border mx-3 mb-2"
      v-if="type === 'group' && (spotlight || iframe)"
    >
      <div class="card-header d-flex align-items-center justify-content-between" @click="setVisibility">
        <div class="d-flex align-items-center">
          <span class="badge bg-black-25 mr-3">{{ index + 1 }}</span>
          <div class="text-muted">{{ getModuleFieldPreview(moduleField) }}</div>
        </div>
        <div v-if="$parent.list && $parent.list.length > 1">
          <button class="btn btn-sm btn-danger flex-end" @click="() => removeFromParent()">
            <i class="uil uil-trash"></i>
          </button>
        </div>
      </div>
      <div class="card-body" v-if="isVisible">
        <div v-bind:class="listItems">
          <page-edit-module-field
            v-for="(child, index) in moduleField.children"
            v-bind:key="index"
            :index="index"
            :affiliateId="affiliateId"
            :last="index + 1 === moduleField.children.length"
            :parent-type="type"
            :site-id="siteId"
            :market-id="marketId"
            :visible-fields="visibleFields"
            :module-field="child"
          />
        </div>
      </div>
    </div>

    <div
      class="col card-body border mx-3 mb-3"
      :class="{ 'p-0': !isVisible }"
      v-else-if="type === 'group' && !(spotlight || iframe)"
    >
      <h3 role="button" @click="setVisibility" :class="{ 'px-4 py-4': !isVisible }">
        # {{ index + 1 }}
        <span v-if="!isVisible">{{ headerTitle }}</span>
      </h3>

      <div v-bind:class="listItems" v-if="isVisible">
        <page-edit-module-field
          v-for="(child, index) in moduleField.children"
          v-bind:key="index"
          :index="index"
          :affiliateId="affiliateId"
          :last="index + 1 === moduleField.children.length"
          :parent-type="type"
          :site-id="siteId"
          :market-id="marketId"
          :visible-fields="visibleFields"
          :module-field="child"
        />

        <div class="col-12 d-flex justify-content-center" v-if="$parent.list && $parent.list.length > 1">
          <button class="btn btn-sm btn-danger flex-end" @click="() => removeFromParent()">
            Delete item
          </button>
        </div>
      </div>
    </div>

    <!-- is list -->

    <div v-else class="col">
      <div class="card-title my-3">
        <div class="form-inline">
          <h5>{{ $prettyLabels(getModuleFieldLabel()) }}</h5>

          <div class="ml-auto">
            <button class="btn btn-secondary btn-sm" @click="duplicate">
              Add item
              <i class="uil uil-plus ml-2"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- v-for="(child, index) in moduleField.children" -->
      <draggable
        handle=".card-header"
        class="sortable-group"
        :disabled="moduleField.field.name !== ('spotlights_list' || 'iframe_clip_list')"
        :list="moduleField.children"
        item-key="id"
      >
        <template #item="{ element, index }">
          <page-edit-module-field
            v-bind:key="index"
            :spotlight="moduleField.field.name === 'spotlights_list'"
            :affiliateId="affiliateId"
            :visible-fields="visibleFields"
            :index="index"
            :last="index + 1 === moduleField.children.length"
            :parent-type="type"
            :module-field="element"
            :site-id="siteId"
            :market-id="marketId"
          />
        </template>
      </draggable>
    </div>
  </div>

  <div v-else-if="(visibleFields === null || visibleFields.includes(moduleField.field.name)) && !hidden">
    <base-radio
      :key="moduleField.value"
      v-if="
        type === 'radio' &&
        getFieldLabel(moduleField.field) === 'cards_selector' &&
        (checkMultiSelect === false || checkPageTypeSelector === false)
      "
      :label="getFieldLabel(moduleField.field)"
      :data="moduleField.field.select_options.split(',')"
      :modelValue="moduleField.value"
      :name="getFieldLabel(moduleField.field)"
      @update:modelValue="(value) => valueChanged(value)"
      :useNameValue="false"
      :inlineLayout="true"
    />

    <base-radio
      v-if="type === 'radio' && getFieldLabel(moduleField.field) === 'Sort cards by'"
      :label="getFieldLabel(moduleField.field)"
      :data="moduleField.field.select_options.split(',')"
      :modelValue="moduleField.value"
      :name="getFieldLabel(moduleField.field)"
      @update:modelValue="(value) => valueChanged(value)"
      :useNameValue="false"
      :inlineLayout="true"
    />

    <base-link
      v-if="type === 'link'"
      :label="getFieldLabel(moduleField.field)"
      :value="getLinkTypeValue(moduleField)"
      :siteId="siteId"
      :marketId="marketId"
      @valueChanged="valueChanged"
    />
    <model-select
      v-if="moduleField.field.name === 'top_list_item_id'"
      :parameters="{ site_id: siteId, market_id: marketId, affiliate_id: affiliateId, with_count: 'items' }"
      model="top_list"
      :custom-label="getModuleFieldLabel()"
      :value="moduleField.value"
      :valueLabel="moduleField.value_label"
      ref="modelSelect-toplistItemId"
      @valuePicked="selectOptionPicked"
    />

    <model-select
      v-else-if="moduleField.field.name === 'widget_id'"
      :parameters="{ site_id: siteId, market_id: marketId, affiliate_id: affiliateId }"
      model="odds"
      :custom-label="getModuleFieldLabel()"
      :value="moduleField.value"
      :valueLabel="moduleField.value_label"
      :helpText="moduleField.field.help_text"
      @valuePicked="selectOptionPicked"
    />

    <model-select
      v-else-if="moduleField.field.name === 'top_list_item_link_value'"
      custom-label="Item link"
      model="page"
      ref="modelSelect"
      modal-size-class="modal-lg"
      :parameters="{ site_id: siteId, market_id: marketId }"
      :value="moduleField.value"
      :valueLabel="moduleField.value_label"
      @valuePicked="selectOptionPicked"
    />

    <base-select
      v-else-if="moduleField.field.name === 'page_list_page_type'"
      label="Page type"
      :modelValue="moduleField.value"
      :custom-options="['page', 'operator', 'game', 'article']"
      v-model="moduleField.value"
    />

    <div v-else-if="moduleField.field.name === 'iframe_source'">
      <iframe-component
        :label="getFieldLabel(moduleField.field)"
        :labelAction="getLabelAction()"
        :labelActionTitle="getLabelActionTitle()"
        :helpText="setHelpText()"
        :moduleField="moduleField"
        :moduleValueFields="$parent.pageModule.module_value_fields"
      />
    </div>

    <base-select
      v-else-if="type === 'select'"
      :label="getFieldLabel(moduleField.field)"
      :modelValue="moduleField.value"
      :clearable="true"
      :custom-options="moduleField.field.select_options.split(',')"
      v-model="moduleField.value"
      @update:modelValue="(value) => $emit('update:modelValue', value)"
    />

    <base-input
      v-else-if="type === 'number'"
      type="number"
      :label="getFieldLabel(moduleField.field)"
      :modelValue="moduleField.value"
      :helpText="setHelpText()"
      v-model="moduleField.value"
    />
    <base-input
      v-else-if="type === 'text'"
      :label="getFieldLabel(moduleField.field)"
      :labelAction="getLabelAction()"
      :labelActionTitle="getLabelActionTitle()"
      :modelValue="moduleField.value"
      :helpText="setHelpText()"
      v-model="moduleField.value"
    />

    <base-rich-text
      v-else-if="type === 'html'"
      :modelValue="moduleField.value"
      :label="getFieldLabel(moduleField.field)"
      @valueChanged="valueChanged"
      :site-id="siteId"
      :market-id="marketId"
    />

    <media
      v-else-if="type === 'image_upload'"
      v-model="moduleField.value"
      :siteSpecific="true"
      :label="getFieldLabel(moduleField.field)"
    />

    <base-checkbox
      :label="getFieldLabel(moduleField.field)"
      :modelValue="moduleField.value"
      v-else-if="type === 'checkbox'"
      @update:modelValue="(value) => (moduleField.value = value)"
      :helpText="setHelpText()"
    />

    <base-color
      v-else-if="type === 'colour_picker'"
      :label="getFieldLabel(moduleField.field)"
      :value="moduleField.value"
      @change="(value) => (moduleField.value = value)"
    />

    <model-select
      v-else-if="['operator', 'banner'].includes(type)"
      :value="moduleField.value"
      :value-label="moduleField.value_label"
      :model="type"
      :parameters="getModelSelectParams(type)"
      @valuePicked="selectOptionPicked"
    />
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox'
import Media from '@molecules/fields/media.vue'
import BaseSelect from '@/components/fields/base-select'
import BaseRichText from '@atoms/fields/base-rich-text'
import ModelSelect from '@molecules/fields/model-select/base-model-select'
import BaseColor from '@atoms/fields/base-color'
import BaseLink from '@molecules/fields/base-link.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import Draggable from 'vuedraggable'

import _ from 'lodash'
import IframeComponent from '@atoms/cms/modules/iframe-component'

export default {
  components: {
    IframeComponent,
    BaseInput,
    BaseRichText,
    BaseSelect,
    ModelSelect,
    Media,
    BaseCheckbox,
    BaseColor,
    BaseLink,
    BaseRadio,
    Draggable,
  },
  props: {
    spotlight: {
      type: Boolean,
      required: false,
    },
    iframe: {
      type: Boolean,
      required: false,
    },
    hidden: {
      type: Boolean,
      required: false,
    },
    moduleField: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
    },
    last: {
      type: Boolean,
    },
    parentType: {
      type: String,
    },
    siteId: {
      type: Number,
      required: true,
    },
    marketId: {
      type: Number,
    },
    affiliateId: {
      type: Number,
      required: true,
    },
    visibleFields: {
      default: () => null,
    },
    checkMultiSelect: {
      type: Boolean,
    },
    checkPageTypeSelector: {
      type: Boolean,
    },
  },
  data() {
    return {
      openListItem: this.$parent.$parent.list && this.$parent.$parent.list.length === 1,
      isVisible: true,
    }
  },
  name: 'page-edit-module-field',
  mounted() {
    // only FAQ modules are not visible by default due to ALPHA-4780
    if (this.moduleField.field.name === 'faq_group' || this.spotlight || this.iframe) {
      this.isVisible = false
    }

    this.$store.dispatch('media/setSiteSpecific', true)
  },
  computed: {
    type() {
      return this.moduleField.field.type
    },
    listItems() {
      return this.moduleField.field.type !== 'group' ? 'd-flex justify-content-between' : ''
    },
    headerTitle() {
      // only FAQ modules are currently returning a title due to ALPHA-4780
      if (this.moduleField.field.name === 'faq_group') {
        return this.moduleField.children[0]?.value?.replace(/<\/?[^>]+(>|$)/g, '')
      }
      return ''
    },
  },
  methods: {
    getModelSelectParams(type) {
      let params = {
        market_id: this.marketId,
      }
      if (type === 'operator') {
        params.affiliate_id = this.affiliateId
      }
      if (type === 'banner') {
        params.site_id = this.siteId
      }
      return params
    },
    getLinkTypeValue(moduleField) {
      return moduleField.value ? JSON.parse(moduleField.value) : { type: 'page', value: null, title: null }
    },
    getLabelAction() {
      return this.parentType === 'list' && this.index > 0 ? this.removeFromParent : null
    },
    getLabelActionTitle() {
      return this.parentType === 'list' && this.index > 0 ? 'remove' : null
    },
    getModuleFieldLabel() {
      const field = this.moduleField.field
      return field.label ? field.label : field.name
    },
    getFieldLabel(field) {
      return field.label ? field.label : field.name
    },
    selectChanged(field, value) {
      this.valueChanged(value)
    },
    valueChanged(value) {
      this.moduleField.value = value
      this.moduleField.updated = true
    },
    duplicate() {
      let last = _.cloneDeep(this.moduleField.children[0])
      last.id = 0
      last.value = this.moduleField.field.default_value
      last.new = true
      last.children.forEach((child) => {
        child.value = child.field.default_value
        child.id = 0
        child.value_id = null
        child.parent_id = 0
        child.value_file = null
      })
      this.moduleField.children.push(last)
      this.showSuccessMessage('Item added')
    },
    isNewListItem() {
      return Object.hasOwn(this.moduleField, 'new')
    },
    removeFromParent() {
      if (this.parentType === 'list') {
        this.$parent.list.splice(this.index, 1)
      }
    },
    wrapperClass() {
      const type = this.moduleField.field.type
      if (type === 'group') {
        return 'row'
      }
      return 'col'
    },
    toplistSelectColumns() {
      return [
        { field: 'label', type: 'text', filterable: true },
        { field: 'status', type: 'text', filterable: true },
        { field: 'items_count', type: 'text', label: 'Items count', filterable: false },
      ]
    },
    selectOptionPicked(value, valueLabel) {
      this.moduleField.value = value
      this.moduleField.value_label = valueLabel
      const items_count = this.$refs['modelSelect-toplistItemId'].selectedRow.items_count || 0
      this.$store.commit('dynamicForm/setToplistCount', {
        count: items_count,
        id: value,
      })
      this.$forceUpdate()
    },
    setHelpText() {
      if (this.moduleField.field.help_text) {
        return this.moduleField.field.help_text
      }
      const name = this.moduleField.field.name
      if (name === 'top_list_item_num_initial_load' || name === 'top_list_item_num_load_more') {
        this.moduleField.field.min = 0
        return 'Use 0 for no limit'
      }
      return ''
    },
    setVisibility() {
      this.isVisible = !this.isVisible
    },
  },
}
</script>

<style lang="scss" scoped>
.sortable-group {
  .card-body {
    cursor: auto;
  }
  .card-header {
    cursor: grab;
  }
}

details {
  padding: 10px 22px;
}

details summary:before {
  content: ' ';
}

details summary.card-header {
  padding: 0;
}

details summary h3 {
  margin-bottom: 0;
}

details .card-body {
  padding: 0;
  padding-top: 15px !important;
}
</style>
