<template>
  <data-table
    model="Role"
    service-file="RolesService"
    path="backoffice/roles"
    :show-edit-modal="false"
    :columns="columns()"
    :column-templates="columnTemplates()"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  data() {
    return {
      title: 'Roles',
    }
  },
  methods: {
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        {
          field: 'labels',
          type: 'text',
          filterable: false,
        },
        { field: 'updated_at', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return {
        labels: function (row) {
          if (!row.labels || !row.labels.length) {
            return
          }

          let labels = row.labels.map((label) => label?.name)

          if (labels.length <= 6) {
            return <span>{labels.join(', ')}</span>
          }

          let displayedLabels = [...labels].splice(0, 6).join(', ') + ' ...'
          let tooltipLabels = labels.join(', ')
          return (
            <span
              data-tooltip={tooltipLabels}
              class={`tooltipLabels${row.labels.length > 200 ? '-200' : ''}`}
            >
              {displayedLabels}
            </span>
          )
        },
      }
    },
  },
}
</script>
