const state = () => ({
  model: null,
  isLoading: true,
  cmsSiteId: null,
})

const mutations = {
  setModel(state, value) {
    state.model = value
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setSiteId(state, value) {
    state.cmsSiteId = value;
  }  
}

export default {
  namespaced: true,
  state,
  mutations,
}
