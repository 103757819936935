<template>
  <div>
    <data-table
      model="AffiliateOperator"
      service-file="AffiliateOperatorsService"
      :path="path()"
      ref="list"
      :column-templates="columnTemplates()"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :serviceParams="[operator_id]"
      :showMinimalRemove="true"
      :create-defaults="createDefaults()"
      :customCreateActionButton="{
        action: submitAndCreateLink,
        title: 'Submit and Create Link',
        icon: 'uil uil-link-add',
        roles: [],
      }"
      :show-remove="false"
      :checkStatusOfOperators="checkStatusOfOperators"
      :enable-bulk-select="true"
      :showEditModal="true"
      :default-filters="filters"
      @bulkClickByIds="bulkClick"
      getCustomDataFunctionName="getArrayOfAffiliateOperatorsObjects"
    />
    <type-edit ref="typesModal" model="affiliate" />
    <affiliate-link-edit ref="linksModal" :showNewLinkModal="true" v-on:refresh="refresh" />
    <site-data-clone ref="siteDataCloneModal" v-on:refresh="refresh" />
    <AffiliateOperatorsEditBulk
      v-if="showBulkEdit"
      ref="bulkEdit"
      @refresh="$refs.list && $refs.list.search()"
      @closed="showBulkEdit = false"
    >
    </AffiliateOperatorsEditBulk>
    <InformModal v-if="showInfoModal" ref="infoModal" @closed="showInfoModal = false" />
  </div>
</template>

<script>
import DataTable from '@templates/table/table.vue'
import TableColumnTemplate from '@constants/table-column-template'
import AffiliateOperatorsEditBulk from '@molecules/dms/operator/sites-data/edit-bulk.vue'
import TypeEdit from '@molecules/dms/bonus/type-edit'
import AffiliateLinkEdit from '@molecules/dms/affiliate-link/edit-modal.vue'
import SiteDataClone from '@molecules/dms/operator/sites-data/clone-modal'
import InformModal from '@molecules/dms/operator/sites-data/inform-modal.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService'
import PagesService from '@services/PagesService'

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}

export default {
  created() {
    this.setTitle(
      `${this.$parent.$parent.title ? this.$parent.$parent.title + ':' : ''} ${this.$route.meta.name}`
    )
  },
  data() {
    return {
      operator_id: this.$route.params['id'],
      filters: null,
      showBulkEdit: false,
      showInfoModal: false,
      pageRelations: [],
    }
  },
  components: {
    DataTable,
    TypeEdit,
    AffiliateLinkEdit,
    SiteDataClone,
    AffiliateOperatorsEditBulk,
    InformModal,
  },
  mounted() {
    const affiliateOperatorId = this.$route.query['affiliate_operator_id']
    if (this.$route.query['link_id'] && affiliateOperatorId) {
      this.$refs.linksModal.show(affiliateOperatorId, this.$route.query['link_id'])
    } else if (affiliateOperatorId && this.$route.query['bonus_type_id']) {
      this.$refs.typesModal.show(
        affiliateOperatorId,
        this.$route.query['affiliate_id'],
        this.$route.query['bonus_type_id']
      )
    }
  },
  beforeMount() {
    if (this.$route.query) {
      this.filters = this.$route.query
    }
  },
  computed: {
    getInitOperatorStatus() {
      return (
        this.$store.state.dynamicForm.initFormObject && this.$store.state.dynamicForm.initFormObject.status
      )
    },
  },
  methods: {
    rowActions() {
      let actions = [
        {
          action: this.toplistsAction,
          title: 'Toplists',
          icon: 'uil uil-list-ul',
          class: 'btn-extra',
        },
        {
          action: this.typesAction,
          title: 'Bonuses',
          icon: 'uil uil-medal',
          class: 'btn-info',
        },
        {
          action: this.linkAction,
          title: 'Links',
          icon: 'uil uil-link',
          class: 'btn-secondary',
        },
        {
          action: this.siteDataCloneAction,
          title: 'Clone',
          permission: 'post.operators/affiliate-operators',
          icon: 'uil uil-share-alt',
          class: 'bg-orange-75',
        },
      ]

      if (this.methodAllowed('delete', this.path())) {
        actions.push({
          action: this.remove,
          title: 'Delete',
          icon: 'uil uil-trash-alt',
          class: 'btn-danger',
        })
      }

      return actions
    },
    checkStatusOfOperators(data) {
      if (data.status !== 'active' && this.getInitOperatorStatus !== data.status) {
        this.showInfoModal = true
        this.$nextTick(() => {
          this.$refs.infoModal.show(this.operator_id, data)
        })
      }
    },
    bulkClick(ids) {
      this.showBulkEdit = true
      this.$nextTick(() => {
        this.$refs.bulkEdit.show(ids)
      })
    },
    columns() {
      return [
        { field: 'type', type: 'text', filterable: true },
        { field: 'site', type: 'text', filterable: true },
        {
          field: 'market.label',
          order_key: 'market_label',
          type: 'text',
          filterable: true,
          label: 'Market',
          template: TableColumnTemplate.MARKET_LABEL,
        },
        {
          field: 'status',
          type: 'text',
          advancedFilter: true,
          showModalAdvanced: false,
          filterable: true,
          customOptions: [
            { label: 'All', id: null },
            { label: 'Active', id: 'active' },
            { label: 'Inactive', id: 'inactive' },
            { label: 'Restricted', id: 'restricted' },
            { label: 'Coming soon', id: 'coming_soon' },
            { label: 'Blacklisted', id: 'blacklisted' },
            { label: 'Not recommended', id: 'not_recommended' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          template: TableColumnTemplate.OPERATOR_STATUS,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    toplistsAction(row) {
      this.$router.push({
        path: `/operators/${row.operator_id}/toplists`,
        query: { markets_label: row.market.label, sites_name: row.affiliate.name, lists_type: row.type },
      })
    },
    typesAction(row) {
      this.$refs.typesModal.show(row.id, row.affiliate_id)
    },
    linkAction(row) {
      this.$refs.linksModal.show({ affiliate_operator_id: row.id })
    },
    siteDataCloneAction(row) {
      this.$refs.siteDataCloneModal.show(row)
    },
    async checkPageRelations(id) {
      try {
        const response = await PagesService.get({ relation_type: 'operator', relation_id: id })
        if (response.data.result) {
          this.pageRelations = response.data.result
        } else {
          console.error('No page relations found or error in response:', response)
          this.pageRelations = []
        }
      } catch (error) {
        console.error('Error fetching page relations:', error)
        this.showErrorMessage('Error fetching page relations')
      }
    },
    async remove(row) {
      await this.checkPageRelations(row.id)

      try {
        let htmlForPageRelations = ''

        if (this.pageRelations.length > 0) {
          const relatedPageIds = this.pageRelations
            .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
            .join('<br>')

          htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
        } else {
          htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
        }

        const confirm = await confirmDelete({
          title: 'Warning',
          content: 'Are you sure you want to delete this item?',
          htmlForPageRelations: htmlForPageRelations,
          actionButtonTitle: 'Delete item',
        })

        if (confirm) {
          this.loading = true

          if (row.id) {
            const deleteResponse = await AffiliateOperatorsService.remove({ id: row.id })

            if (deleteResponse.data?.messages) {
              this.showErrorMessages(deleteResponse.data.messages)
            } else {
              this.showSuccessMessage('Item removed successfully.')
              this.close()
            }
          }
        }
      } catch (error) {
        console.error('Error removing item:', error)
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
        this.refresh()
      }
    },

    path() {
      return `hercules/operators/affiliate-operators?operator_id=${this.$route.params['id']}&with=market,affiliate,ribbons,linked_group&exact_match=status`
    },
    createDefaults() {
      return {
        rating: 4,
        operator_id: parseInt(this.$route.params['id']),
      }
    },
    columnTemplates() {
      return Object.assign({
        site: function (row) {
          return row.affiliate && row.affiliate.website
        },
        status: function (row) {
          return row.status.label
        },
      })
    },
    refresh() {
      this.$refs.list.search()
    },
    async submitAndCreateLink() {
      const id = await this.$refs.list.$refs.createModal.submit()
      if (typeof id !== 'undefined' && id) {
        this.linkAction({ id: id })
      }
    },
  },
}
</script>
