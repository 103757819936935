<template>
  <div class="card">
    <div class="card-body">
      <dynamic-form
        v-if="object"
        form-type="edit"
        ref="form"
        :path="config.url"
        :formFields="structure.list"
        :structure="structure"
        :inCard="true"
        :object="object"
        :on-submit="submit"
        :on-remove="remove"
      />
    </div>
  </div>
</template>

<script>
import TopListService from '@services/TopListsService'
import MultiSelect from 'vue-multiselect'
import DynamicForm from '@/components/fields/DynamicForm'
import TopList from '@models/TopList'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

export default {
  components: { MultiSelect, DynamicForm, ConfirmDialog },
  data() {
    return {
      object: {},
      structure: {},
      config: TopList.getConfig(),
    }
  },
  created() {
    this.object = this.$parent.$parent.topList
    this.$store.commit('dynamicForm/setFormObject', { ...this.object })
    this.structure = TopList.getStructure()
  },
  methods: {
    submit() {
      const self = this
      TopListService.update(this.object)
        .then((response) => {
          if (response.data.messages) {
            self.showErrorMessages(response.data.messages)
          } else {
            self.showSuccessMessage(`${self.structure.config.name} updated`)
            self.$parent.$parent.getTopList()
          }
        })
        .catch(() => self.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        await TopListService.remove({ id: this.$route.params['id'] })
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.showSuccessMessage(`${this.structure.config.name} removed`)
              this.$router.push('/toplists')
            }
          })
          .catch(this.showUnknownErrorMessage)
      }
    },
  },
}
</script>
