const state = {
  formObject: {},
  initFormObject: {},
  formSubmitted: true,
  formIsDirty: false,
  toplists: [],
}

const getters = {
  getFormObject: (state) => {
    return state.formObject
  },
  getInitFormObject: (state) => {
    return state.initFormObject
  },
  getFormSubmitted: (state) => {
    return state.formSubmitted
  },
  getToplistData: (state) => (id) => state.toplists.find((toplist) => toplist.id === id) || null,
}

const mutations = {
  setFormObject(state, value) {
    state.formObject = value
  },
  setInitFormObject(state, value) {
    state.initFormObject = value
  },
  setFormSubmitted(state, value) {
    state.formSubmitted = value
  },
  setFormIsDirty(state, value) {
    state.formIsDirty = value
  },
  setToplistCount(state, { count, id }) {
    const existingToplistIndex = state.toplists.findIndex((toplist) => toplist.id === id)

    if (existingToplistIndex !== -1) {
      state.toplists[existingToplistIndex].count = count
    } else {
      state.toplists.push({ id, count })
    }
  },
  removeToplist(state, id) {
    state.toplists = state.toplists.filter(
      (toplist) => toplist.id !== id && toplist.id !== null && toplist.id !== undefined
    )
  },
  clearToplists(state) {
    state.toplists = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
