<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="row">
      <div class="col">
        <h3>
          <router-link
            :to="{ path: '/affiliates' }"
            data-tooltip="Back to affiliates"
            data-tooltip-position="right center"
          >
            <i class="uil uil-angle-left"></i>
          </router-link>
          <span class="mr-3 ml-2">{{ affiliate?.website }}</span>
          <span class="mr-2"> </span>
          <span v-if="showCallout" class="callout callout-help float-right"
            >Only {{ calloutMessage }} with site data are visible.</span
          >
        </h3>
        <div class="card">
          <div class="card-body d-flex flex-wrap align-items-center">
            <div class="d-flex flex-wrap card-items-gap">
              <span class="font-weight-bold"
                >URL:
                <a :href="`http://${affiliate?.website}`" target="blank">
                  {{ affiliate?.name }}
                </a>
              </span>
              <span class="font-weight-bold"
                >Market:
                <span class="font-weight-normal">{{
                  affiliate && affiliate.default_market && affiliate.default_market.label !== null
                    ? affiliate.default_market.label
                    : 'Not Set'
                }}</span>
              </span>
              <span class="font-weight-bold"
                >Theme:
                <span class="font-weight-normal">{{ affiliate?.theme || affiliate?.framework || '-' }}</span>
              </span>
              <span class="font-weight-bold"
                >Last Updated:
                <span class="font-weight-normal">{{ $dateRelative(affiliate?.updated_at) }}</span>
              </span>
            </div>
            <div class="ml-auto">
              <a :href="`https://dev.${affiliate?.website}`" class="btn btn-secondary ml-2" target="_blank">
                Go to dev
              </a>
              <a
                :href="`https://staging.${affiliate?.website}`"
                class="btn btn-secondary ml-2"
                target="_blank"
              >
                Go to staging
              </a>
              <a
                v-if="affiliate?.framework"
                :href="`https://${affiliate?.website}`"
                class="btn btn-site-login ml-2"
                target="_blank"
              >
                Visit site
              </a>
              <a v-else href="#" class="btn btn-site-login ml-2" @click="siteLogin" target="_blank">
                Go to live
              </a>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav nav-tabs mb-3 overflow-auto">
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}` }" exact>
        <a>Home</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/edit` }" exact>
        <a>General Settings</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/markets-data` }" exact>
        <a>Markets Data</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/linked-markets` }" exact>
        <a>Linked Markets</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/affiliates/${affiliate?.id}/location-groups` }"
        exact
        v-if="affiliate?.link_logic_enabled"
      >
        <a>Location groups</a>
      </router-link>
      <router-link
        tag="li"
        :to="{ path: `/affiliates/${affiliate?.id}/link-logic` }"
        exact
        v-if="affiliate?.link_logic_enabled"
      >
        <a>Link logic</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/operators` }" exact>
        <a>Operator Sites Data</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/games` }" exact>
        <a>Games Sites Data</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/extra-fields-operators` }" exact>
        <a>Extra Operator fields</a>
      </router-link>

      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/extra-fields-games` }" exact>
        <a>Extra Game fields</a>
      </router-link>
      <router-link tag="li" :to="{ path: `/affiliates/${affiliate?.id}/ribbons` }" exact>
        <a>Ribbons</a>
      </router-link>
    </ul>
    <router-view></router-view>
  </div>
</template>

<script>
import AffiliatesService from '@services/AffiliatesService'
import PandoraSiteLoginService from '@services/PandoraSiteLoginService'
// import router from '../../../../../../router'

export default {
  data() {
    return {
      showCallout: false,
      calloutMessage: '',
    }
  },
  updated() {
    this.calloutMessage = this.$route.path.includes('/games') ? 'games' : 'operators'
    this.showCallout = this.$route.path.includes('/games') || this.$route.path.includes('/operators')
  },
  created() {
    if (this.isIdMismatch) {
      this.getAffiliate()
    } else {
      this.$store.commit('editPage/setIsLoading', false)
    }
  },
  computed: {
    isIdMismatch() {
      return this.affiliate?.id !== Number(this.$route.params['id'])
    },
    affiliate() {
      return { ...this.$store.state.editPage.model }
    },
    isLoading() {
      return this.$store.state.editPage.isLoading
    },
  },
  methods: {
    getAffiliate() {
      const data = {
        with: 'default_market',
      }
      AffiliatesService.getOne(this.$route.params['id'], data)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Affiliate not found') >= 0) {
                this.$router.push('/sites')
              }
            })
          } else {
            this.$store.commit('editPage/setModel', response.data.result[0])
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$store.commit('editPage/setIsLoading', false)
        })
    },
    siteLogin() {
      const env = process.env.VUE_APP_ENV

      let siteUrl = this.affiliate.website
      if (['local', 'experiment2', 'dev'].includes(env)) {
        siteUrl = `https://dev.${siteUrl}`
      } else if (env === 'staging') {
        siteUrl = `https://staging.${siteUrl}`
      } else if (env === 'production') {
        siteUrl = `https://${siteUrl}`
      }

      const userData = this.$auth.user()
      const dataToSend = {
        siteUrl: siteUrl,
        username: userData.name
          .split(' ')
          .map((part) => part.toLowerCase())
          .join('.'),
        email: userData.email,
        role: userData.roles,
      }

      PandoraSiteLoginService.post(dataToSend)
        .then((response) => {
          console.log('response', response)
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            const redirectUrl = response.data.result.redirectUrl
            window.open(redirectUrl, '_blank')
          }
        })
        .catch((error) => {
          console.error('Error sending data:', error)
        })
    },
    unmounted() {
      if (this.isIdMismatch) {
        this.$store.commit('editPage/setModel', null)
        this.$store.commit('editPage/setIsLoading', true)
      }
    },
  },
}
</script>
<style scoped>
.card-items-gap {
  gap: 24px;
}
.operational {
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  background-color: #dcfce7;
  color: #16a34a;
}
</style>
