<template>
  <div>
    <data-table
      model="widgets/toplist/general"
      service-file="WidgetsToplistService"
      path="hercules/widgets/toplist?with=site,market,site&bypass_market_restrictions=1"
      :columns="columns()"
      :create-defaults="createDefaults()"
      :column-templates="columnTemplates()"
      :show-edit-modal="false"
      :rowActionButtons="rowActions"
    />

    <preview-widget-modal
      v-if="showModal"
      ref="modal"
      :title="'Preview Toplist Widget - Toplist styles are applied in individual sites'"
      @closeModal="showModal = false"
      :siteTheme="theme"
    />
  </div>
</template>

<script lang="jsx">
import PreviewWidgetModal from '@molecules/widgets/preview-widget-modal.vue'
import TableColumnTemplate from '@constants/table-column-template'

export default {
  components: { PreviewWidgetModal },
  data() {
    return {
      loading: false,
      theme: null,
      rowActions: [
        {
          action: this.previewWidget,
          title: 'Preview',
          icon: 'uil uil-eye',
          class: 'btn-secondary',
        },
      ],
      showModal: false,
    }
  },
  created() {
    this.setTitle()
  },
  methods: {
    previewWidget(row) {
      const env = this.$store.state.app.engimaEnv[this.$store.state.app.environmentName] || ''
      let type = row.type
      type ? (type = type.toLowerCase().replace(/[\s+]/g, '-')) : (type = 'toplist')
      this.showModal = true
      this.$nextTick(() => {
        this.theme = row.theme
        this.$refs.modal.show(
          `https://${env}sportswidget.gigmedia.com/${type}?short_code=${row.short_code}&widget=toplistwidget`,
          row.site_id
        )
      })
    },
    columns() {
      return [
        { field: 'title', type: 'text', filterable: true },
        { field: 'short_code', type: 'text', filterable: true },
        { field: 'site_name', type: 'text', label: 'Site', filterable: true },
        { field: 'market', type: 'text', filterable: true },
        { field: 'type', type: 'text', filterable: true },
        { field: 'toplist_items', label: 'Items', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },

    columnTemplates() {
      return Object.assign({
        market: (row) => {
          if (row.market) return row.market.label
        },
        toplist_items: (row) => {
          return <span class="badge bg-black-10 counter-badge">{row.toplist_items}</span>
        },
      })
    },
    createDefaults() {
      return {
        market_id: null,
      }
    },
  },
}
</script>
