<template>
  <div>
    <data-table
      model="cms/Author"
      service-file="AuthorsService"
      :path="path()"
      :columns="columns()"
      :show-edit-modal="false"
      :column-templates="columnTemplates()"
      :rowActionButtons="rowActions()"
      :show-remove="false"
      ref="list"
    />
    <clone-modal ref="cloneModal" @refresh="$refs.list && $refs.list.search()" />
  </div>
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import AuthorsService from '@services/AuthorsService'
import CloneModal from '@molecules/cms/authors/clone-modal'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import PagesService from '@services/PagesService'

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}

export default {
  components: { CloneModal },
  created() {
    this.setTitle()
  },
  methods: {
    path() {
      return 'hercules/sites/authors?with=site&with_count=pages'
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        { field: 'site', type: 'text', label: 'Site', filterable: true },
        { field: 'pages_count', type: 'text', filterable: true, label: 'Author Pages' },
        { field: 'reviewer_pages_count', type: 'text', filterable: true, label: 'Reviewer Pages' },
        { field: 'active', type: 'text', filterable: true },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    actionColumnClick(id, field, value) {
      this.$refs.list.isLoading = true
      let object = { id: id }
      object[field] = value
      AuthorsService.update(object)
        .then(() => {
          this.$refs.list.search()
          this.showSuccessMessage(`Author set to ${object.active ? 'active' : 'inactive'}`)
        })
        .catch(() => {
          this.showErrorMessage('Failed to update author')
          this.$refs.list.isLoading = false
        })
    },
    rowActions() {
      let actions = [
        {
          action: this.cloneAuthorClick,
          title: 'Clone',
          icon: 'uil uil-share-alt',
          class: 'btn-info',
        },
      ]
      if (this.methodAllowed('delete', this.path())) {
        actions.push({
          action: this.remove,
          title: 'Delete',
          icon: 'uil uil-trash-alt',
          class: 'btn-danger',
        })
      }
      return actions
    },
    cloneAuthorClick(row) {
      this.$nextTick(() => {
        this.$refs.cloneModal.show(row)
      })
    },
    columnTemplates() {
      const self = this
      return Object.assign({
        site: function (row) {
          return row.site.name
        },
        active: function (row) {
          return (
            <EnabledActionColumn
              row={row}
              field={'active'}
              onColumnClick={self.actionColumnClick}
              confirmTitle={'Careful, this action affects the pages!'}
              confirmMessage={
                row.active
                  ? 'Author of the affected pages will be set to none. Do you want to proceed?'
                  : null
              }
              confirmButtonTitle={'Yes'}
              confirmButtonIconClass={''}
              confirmButtonClass={'btn bg-orange'}
            />
          )
        },
      })
    },
    async checkPageRelations(id) {
      try {
        const response = await PagesService.get({ relation_type: 'author', relation_id: id })
        if (response.data.result) {
          this.pageRelations = response.data.result
        } else {
          console.error('No page relations found or error in response:', response)
          this.pageRelations = []
        }
      } catch (error) {
        console.error('Error fetching page relations:', error)
        this.showErrorMessage('Error fetching page relations')
      }
    },
    async remove(row) {
      await this.checkPageRelations(row.id)

      try {
        let htmlForPageRelations = ''

        if (this.pageRelations.length > 0) {
          const relatedPageIds = this.pageRelations
            .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
            .join('<br>')

          htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
        } else {
          htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
        }

        const confirm = await confirmDelete({
          title: 'Warning',
          content: 'Are you sure you want to delete this item?',
          htmlForPageRelations: htmlForPageRelations,
          actionButtonTitle: 'Delete item',
        })

        if (confirm) {
          this.loading = true

          if (row.id) {
            const deleteResponse = await AuthorsService.remove({ id: row.id })

            if (deleteResponse.data?.messages) {
              this.showErrorMessages(deleteResponse.data.messages)
            } else {
              this.showSuccessMessage('Item removed successfully.')
              this.close()
            }
          }
        }
      } catch (error) {
        console.error('Error removing item:', error)
        this.showUnknownErrorMessage()
      } finally {
        this.loading = false
        this.refresh()
      }
    },
    refresh() {
      this.$refs.list.search()
    },
  },
}
</script>
