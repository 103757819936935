<template>
  <div
    class="modal modal-xlarge media-library"
    v-if="visible"
    v-click-outside="close"
    v-bind:class="{ in: visible }"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between pb-3">
          <h5 class="modal-title">
            {{ asset.src === null ? 'Image upload' : 'Clone image' }}
          </h5>
          <button class="close p-0 m-0" v-on:click="close"></button>
        </div>
        <div class="modal-body">
          <div v-if="siteSpecific" class="row mb-3">
            <div class="col">
              <div class="radio" @click="type = 'global'">
                <input type="radio" value="global" v-model="type" />
                <label for="global"
                  >Global
                  <div class="form-text">Image will be uploaded for all sites</div>
                </label>
              </div>
            </div>
            <div class="col" v-if="siteSpecific">
              <div class="radio" @click="type = 'specific'">
                <input type="radio" value="specific" v-model="type" />
                <label for="specific"
                  >Site specific
                  <div class="form-text">Image will be uploaded for a specific site</div>
                </label>
              </div>
            </div>
          </div>
          <input
            v-if="asset.src === null && asset.url === null"
            class="d-none"
            type="file"
            ref="imageInput"
            accept="image/*"
            @change="onImageChoosen"
          />
          <div v-if="asset.url === null" class="form-group mb-2">
            <label>Image</label>
            <button class="btn btn-info mb-2 d-block" v-on:click="chooseImage">
              <i class="uil uil-image-upload"></i> Select image
            </button>
          </div>
          <div v-if="asset.url" class="form-group mb-2">
            <div class="d-flex align-items-center mb-2">
              <label class="m-0">Image</label>
              <span class="text-muted ml-2 clickable" @click="clearImage">clear</span>
            </div>
            <div class="image-container">
              <img :src="asset.url" />
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <base-input
              v-if="asset.url"
              label="File name"
              v-model="asset.filename"
              customClass="w-100 mr-1"
              :valueModifier="filenameModifier"
            />
            <base-input
              v-if="asset.url"
              label="Extension"
              v-model="asset.extension"
              customClass="w-50 ml-1"
              :readOnly="true"
              :disabled="true"
            />
          </div>
          <base-input v-if="asset" label="Title" v-model="asset.title" />
          <base-input v-if="asset" label="Alt Text" v-model="asset.alt" />
          <base-select
            v-if="type === 'specific'"
            wrapperClass="form-group mb-2"
            path="hercules/globals/sites?orderBy=name&ascending=1"
            :loadOnMount="true"
            v-model="asset.site_id"
            label="Site"
            placeholder="Select a site"
            selectLabel="name"
            :allowNullOption="true"
            optionLabelKey="name"
            :required="true"
            emit-option="watermark_asset_id"
            @update:modelValue="siteSelected"
          />
          <base-select
            v-if="type === 'specific'"
            wrapperClass="form-group mb-2"
            path="hercules/globals/markets?orderBy=label&ascending=1"
            :loadOnMount="true"
            v-model="asset.market_id"
            label="Market"
            placeholder="Select a market"
            selectLabel="label"
            :allowNullOption="true"
            optionLabelKey="label"
            :required="true"
          />
        </div>
        <base-checkbox
          v-if="type === 'specific' && hasWatermark"
          label="Add a watermark to the image"
          v-model="asset.watermarked"
          :switch="true"
          class="ml-2 pl-4 pb-3"
        />
        <div class="modal-footer">
          <button
            class="btn btn-success"
            v-on:click="submitImage"
            :disabled="loading"
            :class="{ loading: loading }"
          >
            <i class="uil uil-check"></i> Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import AssetService from '@services/AssetsService'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox'
import GeneralService from '@services/GeneralService'

export default {
  components: { BaseInput, BaseSelect, BaseCheckbox },
  data() {
    return {
      type: 'global',
      visible: false,
      asset: null,
      loading: false,
      hasWatermark: false,
      shouldClone: false,
      filename: '',
    }
  },
  props: {
    selectedSiteId: {
      required: true,
      default: null,
    },
  },
  mounted() {
    this.filename = this.getFormObject?.name
  },
  computed: {
    getFormObject() {
      return this.$store.state.dynamicForm.formObject
    },
    siteSpecific() {
      return this.$store.state.media.siteSpecific
    },
    isStandardised() {
      return this.$store.state.media.standardised
    },
  },
  methods: {
    siteSelected(id, watermark_asset_id) {
      this.hasWatermark = watermark_asset_id !== null
    },
    // null is for create
    show(asset = null) {
      this.asset = asset
        ? { ...asset }
        : {
            src: null,
            url: null,
            alt: null,
            title: null,
            filename: null,
            watermarked: false,
          }
      this.type = asset?.site_id ? 'specific' : 'global'
      this.visible = true
      this.hasWatermark = false
      GeneralService.fetchItems(`hercules/globals/sites/${this.selectedSiteId}`).then((response) => {
        if (response.data.success && response.data.result && response.data.result.length === 1) {
          this.hasWatermark = Number.isInteger(response.data.result[0].watermark_asset_id)
        }
      })
    },
    chooseImage() {
      this.$refs.imageInput.click()
    },
    async onImageChoosen(event) {
      if (event.target.files[0].size > 2097152) {
        this.showErrorMessages('File cannot be greater than 2MB.')
      } else {
        this.image = event
        const file = event.target.files[0]
        this.asset.url = URL.createObjectURL(file)
        this.asset.filename = this.filenameModifier(file.name).replace(/\.[^/.]+$/, '') // remove extension
        this.asset.extension = '.' + file.name.split('.').pop()
        if (this.filename) {
          this.asset.title = this.capitalize(`${this.filename} logo`)
          this.asset.alt = this.capitalize(`Logo image for ${this.filename}`)
        }
      }
    },
    clearImage() {
      if (this.asset && this.asset.url) {
        this.asset.url = null
      }
    },

    submitImage() {
      this.asset.watermarked = Number(this.asset.watermarked)
      if (this.type === 'specific') {
        let errors = []
        if (this.asset.site_id === null) {
          errors.push('Site has to be selected')
        }
        if (this.asset.market_id === null) {
          errors.push('Market has to be selected')
        }
        if (errors.length !== 0) {
          return this.showErrorMessages(errors)
        }
      }
      if (this.shouldClone) {
        this.cloneImage()
      } else {
        this.uploadNewImage()
      }
    },
    async cloneImage() {
      this.loading = true
      let assetClone = Object.assign({ object: true }, this.asset)
      if (this.type !== 'specific') {
        delete assetClone.site_id
        delete assetClone.market_id
      }
      await AssetService.clone(assetClone)
        .then((response) => {
          if (response.data.success) {
            this.$emit('resetAndSelect', response.data.result)
            this.close()
          } else {
            this.showErrorMessages(response.data.messages)
          }
        })
        .catch(this.showErrorMessages)
        .finally(() => {
          this.loading = false
          this.shouldClone = false
          this.clearImage()
        })
    },
    async uploadNewImage() {
      if (this.asset.url) {
        this.asset.standardised = 0

        if (this.isStandardised) {
          this.asset.standardised = 1
        }

        let formData = new FormData()
        formData.append('file', this.image.target.files[0], this.image.target.files[0].name)

        let assetClone = Object.assign({ object: true, url: null }, this.asset)
        if (this.type !== 'specific') {
          delete assetClone.site_id
          delete assetClone.market_id
        }
        this.loading = true

        await AssetService.create(formData, assetClone)
          .then((response) => {
            if (response.data.success) {
              this.$emit('resetAndSelect', response.data.result)
              this.close()
            } else {
              this.showErrorMessages(response.data.messages)
            }
          })
          .catch(this.showErrorMessages)
          .finally(() => {
            this.loading = false
            this.clearImage()
          })
        return
      }
      this.showErrorMessage('Please select an image')
    },
    filenameModifier(filename) {
      return filename.replace(/_/g, '-').replace(/ /g, '-').toLowerCase()
    },
    close() {
      this.visible = false
      this.asset = null
    },
  },
}
</script>

<style scoped>
.thumbnail {
  position: relative;
}

.thumbnail:hover img {
  opacity: 0.2;
}

.thumbnail i {
  display: none;
}

.thumbnail:hover i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected-img-info {
  font-size: 12px;
}

.dimensions > div > div {
  position: relative;
}

.dimensions > div > div:not(.unit):after {
  content: 'px';
  right: 20px;
  position: absolute;
  top: 37px;
}

.dimensions > div > div:not(.unit):nth-child(2)::after {
  right: 10px;
}

.asset-details {
  background: #f9fafa;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
}

.asset-detail-container.loading:before {
  position: absolute;
  top: 45%;
  left: 45%;
  border-color: transparent transparent #2196f3 #2196f3;
}

.loading {
  opacity: 0.5;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
