<template>
  <div v-if="shouldShow" class="row mb-2">
    <div class="col"></div>
    <div class="col-auto">
      <div class="d-flex font-size-sm bg-orange-10 rounded-lg p-2">
        <i class="uil uil-exclamation-triangle mr-2 text-orange" />
        {{ page.relation_warning }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    shouldShow() {
      if (this.page.relation_warning) {
        return true
      }
      return false
    }
  }
}
</script>
