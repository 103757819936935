<template>
    <BaseWrapper>
      <div>
        <label class="date-label" for="date">Date</label>
        <VueDatePicker
          v-model="date"
          range
          :clearable="false"
          cancel-text="Close"
          input-class-name="dp-custom-input"
          position="left"
          :disabled-dates="disabledDates"
          :min-range="1"
          :max-range="4"
        />
      </div>
      <div class="d-flex">
      <base-select
        label="Platforms"
        v-model="platforms"
        path="koala/v1/platforms?orderBy=name&ascending=1&limit=100"
        option-label-key="name"
        :placeholder="platformPlaceholder"
        :disabled="platformDisabled"
        :clearable="platformClearable"
        :loadOnMount="false"
        class="pr-2 pl-2 mb-0"
        ref="platformsRef"
        :searchBy="'name'"
      />
      <koala-advanced-filter :column="platformsColumn" @filterChange="advancedFilter" @clear-filter="clearFilter"/>
    </div>
    <div class="d-flex">
      <base-select
        label="Operators"
        v-model="operators"
        :path="operatorPath"
        option-label-key="name"
        :placeholder="operatorPlaceholder"
        :disabled="operatorDisabled"
        :clearable="operatorClearable"
        :loadOnMount="false"
        class="pr-2 pl-2 mb-0"
        ref="operatorsRef"
        :searchBy="'name'"
      />
      <koala-advanced-filter :column="operatorsColumn" @filterChange="advancedFilter" @clear-filter="clearFilter"/>
    </div>
    <div class="d-flex">
      <base-select
        label="Units"
        v-model="units"
        option-label-key="name"
        :placeholder="unitPlaceholder"
        :disabled="unitDisabled"
        :clearable="unitClearable"
        :loadOnMount="false"
        path="koala/v1/business-units?orderBy=name&ascending=1&limit=100"
        class="pr-2 pl-2 mb-0"
        ref="unitsRef"
        :searchBy="'name'"
      />
      <koala-advanced-filter :column="unitsColumn" @filterChange="advancedFilter" @clear-filter="clearFilter"/>
    </div>
      <template #cards>
        <div class="p-3 dashboard-cards">
          <BaseCard
            v-for="(item, index) in dashboardItems"
            :key="index"
            :title="item.title"
            :value="item.value"
          />
        </div>
      </template>
    </BaseWrapper>
  </template>
  
<script>
import moment from 'moment';
import Api from '@services/Api';
import BaseSelect from '@/components/fields/base-select';
import BaseCard from './BaseCard.vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import BaseWrapper from './BaseWrapper.vue';
import KoalaAdvancedFilter from './KoalaAdvancedFilter.vue';
import AdvancedFilterMixin from '../mixins/advancedFilter'

export default {
    mixins: [AdvancedFilterMixin],
    components: {
        BaseSelect,
        BaseCard,
        VueDatePicker,
        BaseWrapper,
        KoalaAdvancedFilter
    },
    data() {
      return {
        date: [
          moment().subtract(1, 'days').startOf('day').toDate(),
          moment().subtract(1, 'days').endOf('day').toDate()
        ],
        dashboardData: {
          auto_disabled_accounts: 0,
          accounts_without_stats: 0,
          accounts_with_login_errors: 0,
          accounts_with_retrieve_errors: 0,
          accounts_with_process_errors: 0
        },
        platforms: null,
        operators: null,
        units: null,
        defaultOperatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
        operatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
      };
    },
    computed: {
      disabledDates() {
        const twoWeeksAgo = moment().subtract(14, 'days').startOf('day');
        const today = moment().startOf('day');
        return (date) => {
          return date < twoWeeksAgo.toDate() || date > today.toDate();
        };
      },
      dashboardItems() {
        return [
          { title: 'Auto Disabled Accounts', value: this.dashboardData.auto_disabled_accounts },
          { title: 'Accounts without stats', value: this.dashboardData.accounts_without_stats },
          { title: 'Accounts with login errors', value: this.dashboardData.accounts_with_login_errors },
          { title: 'Accounts with retrieve errors', value: this.dashboardData.accounts_with_retrieve_errors },
          { title: 'Accounts with process errors', value: this.dashboardData.accounts_with_process_errors },
        ];
      },
    },
    watch: {
      date(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.fetchDashboardData();
        }
      }
    },
    mounted() {
      this.fetchDashboardData();
    },
    methods: {
      async fetchDashboardData() {
        const [startDate, endDate] = this.date.map(d => moment(d).format('YYYY-MM-DDTHH:MM'));
        const params = {
          from_date: startDate,
          to_date: endDate || startDate,
          platforms: this.platforms,
          operators: this.operators,
          business_units: this.units,
        };
  
        try {
          const [autoDisabledRes, withoutStatsRes, loginErrorsRes, retrieveErrorsRes, processErrorsRes] = await Promise.all([
            Api().get(`koala/v1/dashboard/auto-disabled-accounts`, { params }),
            Api().get(`koala/v1/dashboard/accounts-without-stats`, { params }),
            Api().get(`koala/v1/dashboard/accounts-with-errors`, { params: { ...params, error_type: 'login' } }),
            Api().get(`koala/v1/dashboard/accounts-with-errors`, { params: { ...params, error_type: 'retrieve' } }),
            Api().get(`koala/v1/dashboard/accounts-with-errors`, { params: { ...params, error_type: 'process' } })
          ]);
          this.dashboardData.auto_disabled_accounts = autoDisabledRes.data?.count || 0;
          this.dashboardData.accounts_without_stats = withoutStatsRes.data?.count || 0;
          this.dashboardData.accounts_with_login_errors = loginErrorsRes.data?.count || 0;
          this.dashboardData.accounts_with_retrieve_errors = retrieveErrorsRes.data?.count || 0;
          this.dashboardData.accounts_with_process_errors = processErrorsRes.data?.count || 0;
        } catch (error) {
          console.error('Failed to fetch dashboard data', error);
        }
      },
      operatorChanged() {
        if (!this.operators && this.platforms) {
          this.platformChanged();
        } else {
          this.fetchDashboardData();
        }
      },
      platformChanged() {
        if (!this.operators && this.platforms) {
          this.fetchDashboardData();
        } else {
          this.operatorPath = this.defaultOperatorPath;
          this.$refs.operatorsRef.options = [];
          this.$refs.operatorsRef.activeOptionLabel = "";
          this.$refs.operatorsRef.data = "";
        }
      },
      unitChanged() {
        if (this.units) {
          this.fetchDashboardData();
        } else {
          if (this.operators) {
            this.operatorChanged();
          } else {
            this.fetchDashboardData();
          }
        }
      }
    }
};
  </script>
  
  <style scoped>
  .dp__theme_light {
    width: 100%;
    min-width: 380px !important;
  }
  .dashboard-cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }
  
  @media (min-width: 768px) {
    .dashboard-cards {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1200px) {
    .dashboard-cards {
      grid-template-columns: repeat(5, 1fr);
    }
  }
  </style>
  