<template>
  <data-table
    model="Affiliate"
    service-file="AffiliatesService"
    :path="path()"
    :columns="columns()"
    :show-edit-modal="false"
  />
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template'

export default {
  created() {
    this.setTitle()
  },
  methods: {
    columns() {
      return [
        { field: 'id', type: 'text', filterable: true },
        { field: 'website', type: 'text', filterable: true },
        { field: 'site_abbreviation', label: 'abbreviation', type: 'text', filterable: true},
        { field: 'operators_count', type: 'text', filterable: true },
        { field: 'unit', type: 'text', filterable: true },
        { field: 'tier', type: 'text', filterable: true },
        { field: 'framework', type: 'text', filterable: true},
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE }
      ]
    },
    path() {
      return 'hercules/operators/affiliates?with_count=operators'
    }
  }
}
</script>
