<template>
    <div class="koala-card container-fluid p-3" @click="handleClick">
      <div v-if="title" class="koala-card-header text-center h-30">
        {{ title }}
      </div>
      <div class="koala-card-body d-flex justify-content-center align-items-center h-50">
        <AnimatedNumber :value="displayValue" :duration="1000" />
      </div>
    </div>
  </template>
  
<script setup>
import AnimatedNumber from '@atoms/misc/animated-number.vue';
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    value: {
        type: Number,
        required: false,
        default: 0
    },
    isClickable: {
        type: Boolean,
        required: false,
        default: false
    }
});
  
const displayValue = computed(() => {
    return props.value !== null && props.value !== undefined ? props.value : 0;
});

const emit = defineEmits(['card-clicked']);

const handleClick = () => {
    if(props.isClickable){
        emit('card-clicked', props.title);
    }
};
</script>
  
  <style scoped>
  .koala-card {
    background: #f1f2f9;
    /* border: 1px solid black; */
    border-radius: 8px;
    min-height: 180px;
    margin: 0 16px 0 0;
    cursor: pointer;
  }
  .koala-card-header {
    font-size: 16px;
    color: black;
    font-weight: 700;
  }
  .koala-card-body {
    font-size: 36px;
    font-weight: 700;
    color: black;
  }
  </style>
  