<template>
  <BaseWrapper>
    <div class="d-flex">
      <base-select
        label="Platforms"
        v-model="platforms"
        path="koala/v1/platforms?orderBy=name&ascending=1&limit=100"
        option-label-key="name"
        :placeholder="platformPlaceholder"
        :disabled="platformDisabled"
        :clearable="platformClearable"
        :loadOnMount="false"
        class="pr-2 pl-2 mb-0"
        ref="platformsRef"
      :searchBy="'name'"
      />
      <koala-advanced-filter :column="platformsColumn" @filterChange="advancedFilter"/>
    </div>
    <div class="d-flex">
      <base-select
        label="Operators"
        v-model="operators"
        :path="operatorPath"
        option-label-key="name"
        :placeholder="operatorPlaceholder"
        :disabled="operatorDisabled"
        :clearable="operatorClearable"
        :loadOnMount="false"
        class="pr-2 pl-2 mb-0"
        ref="operatorsRef"
      :searchBy="'name'"
      />
      <koala-advanced-filter :column="operatorsColumn" @filterChange="advancedFilter"/>
    </div>
    <div class="d-flex">
      <base-select
        label="Units"
        v-model="units"
        option-label-key="name"
        :placeholder="unitPlaceholder"
        :disabled="unitDisabled"
        :clearable="unitClearable"
        :loadOnMount="false"
        path="koala/v1/business-units?orderBy=name&ascending=1&limit=100"
        class="pr-2 pl-2 mb-0"
      :searchBy="'name'"
        ref="unitsRef"
      />
      <koala-advanced-filter :column="unitsColumn" @filterChange="advancedFilter"/>
    </div>
    <template #cards>
      <div class="p-3 dashboard-cards">
        <BaseCard
          v-for="(item, index) in dashboardItems"
          :key="index"
          :title="item.title"
          :value="item.value"
          :isClickable="true"
          @card-clicked="handleCardClick"
        />
      </div>
    </template>
  </BaseWrapper>
</template>

<script>
import Api from '@services/Api';
import BaseSelect from '@/components/fields/base-select';
import BaseCard from './BaseCard.vue';
import BaseWrapper from './BaseWrapper.vue';
import KoalaAdvancedFilter from './KoalaAdvancedFilter.vue';
import AdvancedFilterMixin from '../mixins/advancedFilter'

export default {
  mixins: [AdvancedFilterMixin],
  components: {
    BaseSelect,
    BaseCard,
    BaseWrapper,
    KoalaAdvancedFilter,
  },
  data() {
    return {
      dashboardData: {
        total_accounts: 0,
        active_accounts: 0,
        active_disabled_accounts: 0,
        closed_accounts: 0,
        merged_accounts: 0,
        migrated_accounts: 0,
      },
      platforms: null,
      operators: null,
      units: null,
      defaultOperatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
      operatorPath: "koala/v1/operators?orderBy=name&ascending=1&limit=100",
    };
  },
  computed: {
    dashboardItems() {
      return [
        { title: 'Total Accounts', value: this.dashboardData.total_accounts },
        { title: 'Active Accounts', value: this.dashboardData.active_accounts },
        { title: 'Active + Disabled Accounts', value: this.dashboardData.active_disabled_accounts },
        { title: 'Closed Accounts', value: this.dashboardData.closed_accounts },
        { title: 'Merged Accounts', value: this.dashboardData.merged_accounts },
        { title: 'Migrated Accounts', value: this.dashboardData.migrated_accounts },
      ];
    },
  },
  mounted() {
    this.setTitle();
    this.getDashboardData('koala/v1/dashboard/accounts');
  },
  methods: {
    async getDashboardData(url) {
      try {
        const response = await Api().get(url);
        Object.assign(this.dashboardData, response.data.result[0] || {});
      } catch (error) {
        console.error('Failed to fetch dashboard data', error);
      }
    },
    async platformChanged(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!newValue) {
          this.operators = null;
          this.operatorPath = this.defaultOperatorPath;
          this.$refs.operatorsRef.options = [];
        }
        this.fetchDashboardData();
      }
    },
    async operatorChanged() {
      this.fetchDashboardData();
    },
    async unitChanged() {
      this.fetchDashboardData();
    },
    fetchDashboardData() {
      let url = `koala/v1/dashboard/accounts`;
      let params = [];
      if (this.platforms) {
        params.push(`platforms=${this.platforms}`);
      }
      if (this.operators) {
        params.push(`operators=${this.operators}`);
      }
      if (this.units) {
        params.push(`business_units=${this.units}`);
      }
      if (params.length) {
        url += `?${params.join('&')}`;
      }
      this.getDashboardData(url);
    },
    handleCardClick(title) {
      let path = '/koala-accounts';
      let query = {};

      if (title === 'Active Accounts') {
        query.status = 'Active';
      } else if (title === 'Active + Disabled Accounts') {
        query.status = 'Active';
        query.active = 'false';
      } else if (title === 'Closed Accounts') {
        query.status = 'Closed';
      } else if (title === 'Merged Accounts') {
        query.status = 'Merged';
      } else if (title === 'Migrated Accounts') {
        query.status = 'Migrated';
      }

      if (this.platforms) {
        query.platforms = this.platforms;
      }
      if (this.operators) {
        query.operators = this.operators;
      }
      if (this.units) {
        query.business_units = this.units;
      }

      let routeData = this.$router.resolve({
        path,
        query,
      });
      window.open(routeData.href, '_blank');
    }
  }
};
</script>

<style scoped>
.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

@media (min-width: 768px) {
  .dashboard-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .dashboard-cards {
    grid-template-columns: repeat(6, 1fr);
  }
}
.filter-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
}

@media (min-width: 1200px) {
  .filter-container {
    flex-direction: row;
  }
}
.card-header {
  padding: 12px !important;
}
</style>


