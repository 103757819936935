<template>
  <div class="card">
    <dynamic-form
      ref="form"
      form-type="edit"
      :formFields="structure.list"
      :object="widget"
      :on-submit="update"
      :inCard="true"
      :path="config.url"
      :fixedButtons="true"
    />
  </div>
</template>

<script>
import WidgetOddsService from '@services/WidgetOddsService'
import OddsWidget from '@models/widgets/odds/odds'
import DynamicForm from '../../../../fields/DynamicForm'

export default {
  components: { DynamicForm },
  data() {
    return {
      widget: null,
      config: OddsWidget.getConfig(),
    }
  },
  created() {
    this.getWidget()
    this.structure = OddsWidget.getStructure()
  },
  methods: {
    getWidget() {
      WidgetOddsService.getOne(this.$route.params.id).then((response) => {
        this.widget = response.data.result[0]
      })
    },
    async update() {
      await WidgetOddsService.update(this.widget)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.widget = response.data.result[0]
            this.$parent.$parent.widgetsList = response.data.result[0]
            this.showSuccessMessage('Widget saved')
            this.$parent.$parent.getWidget()
          }
          this.loading = false
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.$refs.form.isLoading = false
        })
    },
  },
}
</script>
