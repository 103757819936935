import FieldType from '@constants/field-type'

export default {
  getForm() {
    return [
      {
        name: 'site_id',
        search_url: 'hercules/sites/sites',
        required: true,
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'site.name',
      },
      {
        name: 'country_id',
        search_url: 'hercules/globals/countries',
        required: false,
        display_name: 'Country',
        type: 'list-one',
        selectLabel: 'name',
      },
      {
        name: 'lives_in',
        required: false,
        type: 'text'
      },
      {
        name: 'language_id',
        search_url: 'hercules/globals/languages',
        required: false,
        display_name: 'Language',
        type: 'list-one',
        selectLabel: 'name',
      },
      {
        name: 'name',
        required: true,
        display_name: 'Name',
        type: 'text',
        help_text: 'Maximum 100 characters',
        maxlength: 100,
      },
      {
        name: 'display_name',
        display_name: 'Display Name',
        type: 'text',
        help_text: 'Overrides the name field',
      },
      {
        name: 'pronouns',
        required: false,
        type: 'text'
      },
      {
        name: 'knows_abouts',
        type: FieldType.SITES_KNOWS_ABOUT,
        display_name: 'Knows About',
      },
      {
        name: 'author_title',
        required: false,
        display_name: 'Author Title',
        type: 'text',
      },
      {
        name: 'experience',
        required: false,
        display_name: 'Experience',
        type: 'text',
      },
      {
        name: 'university',
        required: false,
        display_name: 'University (Alumni of)',
        type: 'text'
      },
      {
        name: 'degree',
        required: false,
        type: 'text'
      },
      {
        name: 'favorite_casino',
        required: false,
        type: 'text'
      },
      {
        name: 'favorite_sportsbook',
        required: false,
        type: 'text'
      },
      {
        name: 'image_asset_id',
        required: false,
        display_name: 'Image',
        type: FieldType.BASE_MEDIA_SITE_SPECIFIC,
      },
      {
        name: 'biography',
        required: false,
        display_name: 'Biography',
        type: 'html',
        only_edit: true,
      },
      {
        name: 'biography_placeholder',
        required: false,
        disabled: true,
        display_name: 'Biography',
        type: 'text',
        only_create: true,
        help_text: 'Biography can be changed when editing an author',
      },
      {
        name: 'image_alt',
        required: false,
        display_name: 'Image Alt',
        type: 'text',
      },
      {
        name: 'profile_page_path',
        display_name: 'Profile page path',
        type: 'text',
        help_text: 'Example: /authors/john-doe',
      },
      {
        name: 'ribbon_label',
        required: false,
        display_name: 'Author Ribbon Label',
        type: 'text',
      },
      {
        name: 'expertise',
        required: false,
        display_name: 'Expertise',
        type: 'tag',
      },
      {
        name: 'twitter_profile',
        required: false,
        display_name: 'Twitter',
        type: 'text',
      },
      {
        name: 'facebook_profile',
        required: false,
        display_name: 'Facebook',
        type: 'text',
      },
      {
        name: 'linkedin_profile',
        required: false,
        display_name: 'Linkedin',
        type: 'text',
      },
      {
        name: 'instagram_profile',
        required: false,
        display_name: 'Instagram',
        type: 'text',
      },
      {
        name: 'personal_website',
        required: false,
        display_name: 'Personal Website',
        type: 'text',
      },
      {
        name: 'email_address',
        required: false,
        display_name: 'Email',
        type: 'text',
      },
      {
        name: 'additional_url',
        required: false,
        display_name: 'Additional URL',
        type: 'text',
      },
      {
        name: 'tik_tok',
        display_name: 'TikTok',
        required: false,
        type: 'text',
      },
    ]
  },
  getConfig() {
    return {
      url: 'sites/authors',
      name: 'Author',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
