<template>
  <data-table
    model="AffiliateLocationGroup"
    service-file="AffiliateLocationGroupsService"
    :path="path()"
    :columns="columns()"
    :columnTemplates="columnTemplates()"
    :create-defaults="createDefaults()"
    ref="list"
  />
</template>

<script lang="jsx">
import TableColumnTemplate from '@constants/table-column-template'

export default {
  mounted() {
    this.setTitle(`${this.$store.state.editPage.model?.website}: Location groups`)
  },
  methods: {
    path() {
      return `hercules/operators/affiliate-location-groups?affiliate_id=${this.$route.params['id']}&with=locations`
    },
    columns() {
      return [
        { field: 'name', type: 'text', filterable: true },
        {
          field: 'locations',
          type: 'text',
          sortable: false,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    createDefaults() {
      return {
        affiliate_id: parseInt(this.$route.params['id']),
      }
    },
    columnTemplates() {
      return {
        locations: function (row) {
          if (!row.locations || !row.locations.length) {
            return <span>N/A</span>
          }

          let locations = row.locations.map((country) => country.name)

          if (locations.length <= 6) {
            return <span>{locations.join(', ')}</span>
          }

          let displayedCountries = [...locations].splice(0, 6).join(', ') + ' ...'
          let tooltipCountries = locations.join(', ')

          return (
            <span
              data-tooltip={tooltipCountries}
              class={`tooltipCountries${row.locations.length > 200 ? '-200' : ''}`}
            >
              {displayedCountries}
            </span>
          )
        },
      }
    },
  },
}
</script>
