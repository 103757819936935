<template>
  <div class="card">
    <div class="card-body">
      <div class="form-group">
        <div
          class="d-flex align-items-center cursor-pointer mb-3"
          @click="toggleCollapse"
          style="cursor: pointer"
        >
          <h3>{{ label }}</h3>
          <i :class="collapsed ? 'uil uil-angle-down' : 'uil uil-angle-up'"></i>
        </div>
        <div v-if="!collapsed" class="border py-3">
          <div
            class="border border-light mx-3 mb-3"
            v-for="(record, key) in records"
            :key="key"
            :class="{ 'row-removed': record.removed }"
          >
            <div class="m-3">
              <div class="text-muted">Authors entry #{{ key }}</div>
              <div class="d-flex" style="gap: 16px">
                <base-select
                  :loadOnMount="false"
                  optionLabelKey="name"
                  v-model="record.id"
                  :customOptions="getFilteredAuthors(record.id)"
                  class="pr-2 pl-2 mb-0"
                  :disabled="record.removed"
                />
                <base-rich-text
                  :modelValue="record.contribution_value"
                  height="120"
                  :toolbarInline="true"
                  @valueChanged="(value) => (record.contribution_value = value)"
                  :disabled="record.removed"
                />
              </div>
              <a class="btn btn-secondary btn-sm h-50 align-self-start mt-4" @click="deleteRow(key)">
                <i class="uil uil-trash-alt mr-2"></i>Delete entry #{{ key }}
              </a>
            </div>
          </div>
          <div class="d-flex">
            <button
              class="btn btn-primary btn-sm ml-3"
              @click="addRow"
              :disabled="records.length >= authors.length"
            >
              <i class="uil uil-plus-alt mr-2"></i>+ Add {{ records.length === 0 ? 'a' : 'another' }} author
              contribution
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from '@/components/fields/base-select'
import BaseRichText from '@atoms/fields/base-rich-text'

export default {
  components: { BaseSelect, BaseRichText },
  props: {
    label: String,
    modelValue: {
      type: Array,
      default: () => [],
    },
    authors: {
      type: Array,
    },
  },
  data() {
    return {
      collapsed: true,
    }
  },
  watch: {
    authors: {
      handler(newAuthors) {
        newAuthors.forEach((author) => {
          this.records.forEach((rec) => {
            if (rec.id === author.id && author.contribution_value) {
              rec.contribution_value_id = author.contribution_value_id
            }
          })
        })
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    records: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed
    },
    deleteRow(index) {
      const updatedModelValue = [...this.modelValue]

      if (updatedModelValue[index]) {
        updatedModelValue[index].contribution_value = null
        updatedModelValue[index].removed = true
      }
      this.records = updatedModelValue
    },
    addRow() {
      if (this.records.length < this.authors.length) {
        this.records.push({
          id: 0,
          contribution_value: null,
          removed: false,
        })
      }
    },
    initializeRecords() {
      const initialRecords = this.authors
        .filter((author) => author.contribution_value !== null)
        .map((author) => ({
          id: author.id,
          contribution_value: author.contribution_value,
          contribution_value_id: author.contribution_value_id,
          removed: false,
        }))
      this.records = initialRecords
      this.addRow()
    },
    getFilteredAuthors(selectedId) {
      const selectedIds = this.records.filter((record) => !record.removed).map((record) => record.id)
      return this.authors.filter((author) => author.id === selectedId || !selectedIds.includes(author.id))
    },
  },
  mounted() {
    this.initializeRecords()
  },
}
</script>

<style scoped>
h3 {
  margin-bottom: 0 !important;
}
.cursor-pointer {
  cursor: pointer;
}
.row-removed {
  opacity: 0.5;
  pointer-events: none;
}
</style>
