<template>
  <div class="loading-opacity" v-bind:class="{ 'is-loading': breadcrumbLoading }">
    <div class="mb-2 mt-2">
      <colaborative-edit-notification
        v-if="showColaborativeNotification"
        :route_path="$route.fullPath"
        :username="username"
      />
    </div>
    <div class="mb-3 mt-2">
      <span v-if="marketId" class="badge bg-green ml-1">{{ this.$store.state.sidebar.market.label }}</span>
      <span v-if="pageType" class="badge bg-orange ml-2">{{ this.$store.state.sidebar.pageType.name }}</span>
      <span v-if="categoryId" class="badge bg-black-25 ml-2">{{
        this.$store.state.sidebar.category.name
      }}</span>
    </div>
    <div class="row">
      <div class="col">
        <div class="card pb-4">
          <div class="mt-x d-flex flex-wrap justify-content-between">
            <div class="card-header pb-0">
              <h3 class="ml-2">Example</h3>
            </div>
            <div class="card-header pb-0">
              <span @click="openModalInformation" class="btn btn-outline-info btn-md">
                <i class="uil uil-info-circle"></i>Help
              </span>
            </div>
          </div>
          <div class="card-body pb-0">
            <breadcrumb-edit-example :breadcrumbs="breadcrumbs" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card pb-4 bg-white-2">
          <div class="card-header">
            <h3>Items</h3>
            <div class="ml-auto d-flex align-items-center">
              <span class="text-muted mr-2">
                Save your changes
                <strong>before</strong> changing market or page type
              </span>
              <i class="uil uil-exclamation-circle mr-3 text-muted" />
              <button
                :disabled="showColaborativeNotification"
                class="btn btn-primary"
                v-bind:class="{ loading: loading }"
                @click="update"
              >
                <i class="uil uil-check mr-2"></i>Save
              </button>
            </div>
          </div>
          <div class="card-body pb-0">
            <draggable v-model="breadcrumbs" item-key="name">
              <template #item="{ element, index }">
                <breadcrumb-edit-item
                  v-bind:key="index"
                  :breadcrumb="element"
                  :index="index"
                  @remove="remove(index)"
                />
              </template>
            </draggable>
            <div class>
              <button class="btn btn-secondary mr-2" @click="add">
                <i class="uil uil-plus mr-2"></i>Add new
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <information-modal ref="informationModal" :id="confluenceId" />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import BreadcrumbsService from '@services/BreadcrumbsService'
import BreadcrumbEditItem from '@molecules/cms/sites/breadcrumb/edit-item.vue'
import BreadcrumbEditExample from '@atoms/cms/sites/breadcrumb/edit-example.vue'
import InformationModal from '@molecules/cms/modals/information-modal'
import confluenceIds from '@constants/confluence-ids'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification'

export default {
  components: {
    Draggable,
    BreadcrumbEditItem,
    BreadcrumbEditExample,
    InformationModal,
    ColaborativeEditNotification,
  },
  data() {
    return {
      breadcrumbs: [],
      loading: false,
      breadcrumbLoading: true,
      confluenceId: 0,
      showColaborativeNotification: false,
      modelName: 'sites-breadcrumbs',
      username: '',
      initBreadcrumbs: [],
    }
  },
  computed: {
    siteId() {
      return this.$store.state.sidebar.siteId
    },
    marketId() {
      return this.$store.state.sidebar.market
    },
    pageType() {
      return this.$store.state.sidebar.pageType
    },
    categoryId() {
      return this.$store.state.sidebar.category
    },
  },
  watch: {
    pageType() {
      if (this.marketId && this.pageType) this.getBreadcrumbs()
    },
    categoryId() {
      if (this.pageType && this.marketId) this.getBreadcrumbs()
    },
    marketId() {
      if (this.marketId && this.pageType) this.getBreadcrumbs()
    },
  },
  created() {
    this.confluenceId = confluenceIds.sites.breadcrumbs.id
    let pusherChannel = window.pusher.subscribe('updateModelChannel')

    pusherChannel.bind('updateModel', (message) => {
      let ids = JSON.parse(message.modelId)
      if (
        message.modelName === this.modelName &&
        Number(ids.page_type_id) === Number(this.pageType) &&
        Number(ids.market_id) === Number(this.marketId) &&
        message.pusherToken !== this.$store.state.app.uniqueKey
      ) {
        this.showColaborativeNotification = true
        this.username = message.username
      }
    })
  },
  mounted() {
    if (this.marketId && this.pageType) this.getBreadcrumbs()
    if (!this.initBreadcrumbs.length) this.initBreadcrumbs = [...this.breadcrumbs]
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    async getBreadcrumbs() {
      this.breadcrumbLoading = true
      await BreadcrumbsService.get({
        site_id: this.siteId,
        market_id: this.marketId,
        page_type_id: this.pageType,
        with: 'site',
        category: this.categoryId ? this.categoryId : '',
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.breadcrumbs = response.data.result
            this.modelId = this.siteId
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.breadcrumbLoading = false
        })
    },
    async update() {
      this.loading = true
      await BreadcrumbsService.update({
        page_type_id: this.pageType,
        site_id: this.siteId,
        market_id: this.marketId,
        category_id: this.categoryId,
        breadcrumbs: this.breadcrumbs,
      })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.breadcrumbs = response.data.result
            this.showSuccessMessage('Success')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    add() {
      this.breadcrumbs.push({
        id: null,
        label: null,
        market_id: this.marketId,
        page_type_id: this.pageType,
        site_id: this.siteId,
        page_title: null,
        page_path: null,
        page_id: null,
      })
    },
    remove(index) {
      this.breadcrumbs.splice(index, 1)
    },
  },
}
</script>
