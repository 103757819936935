import Api from './Api'

export default {
  getAll(dataTypeId) {
    console.log(dataTypeId)
    return Api().get(`hercules/widgets/odds-sub-types?type_id=${dataTypeId}`)
  },
  getOne(id, data) {
    return Api().get(`hercules/widgets/odds-sub-types/${id}?&with=odds_type`, {
      params: data
    })
  },
  post(data) {
    return Api().post(`hercules/widgets/odds-sub-types`, data)
  },
  update(data) {
    return Api().put(`hercules/widgets/odds-sub-types/${data.id}`, data)
  },
  remove(row) {
    return Api().delete(`hercules/widgets/odds-sub-types/${row.id}`)
  }
}
