<template>
  <div :class="wrapperClass" :hidden="type == 'hidden'">
    <label v-if="label" class>{{ $prettyLabels(label) }}</label>

    <span v-if="required">&nbsp;*&nbsp;</span>

    <div class="d-inline">
      <template v-if="notifyFieldsReady() && options.length > 0 && !readOnly">
        <a class="select-all text-muted ml-2" v-if="max === 0" v-on:click="selectAll(field.name)">
          select all
        </a>

        <span class="text-muted ml-1 mr-1">/</span>

        <a class="deselect-all text-muted" v-on:click="deselectAll(field.name)">deselect all</a>
      </template>
    </div>

    <template v-if="quickAdd && !readOnly">
      <button class="quick-add-btn ml-2" @click.prevent="openQuickAddModal">+ Quick add</button>
      <quick-add-modal
        v-if="showQuickAddModal"
        ref="quickAddModal"
        :modelAndService="quickAdd"
        :labelOf="label"
      />
    </template>

    <div
      class="dropdown"
      v-if="options.length > 0 || !loadOnMount || !notifyFieldsReady() || searchBy"
      :class="{ disabled: !notifyFieldsReady() || readOnly }"
      :style="readOnly ? 'pointer-events: none' : ''"
    >
      <div
        class="multiselect__arrow"
        :class="{ active: showDropdown }"
        :style="readOnly ? 'display: none' : ''"
        @click="toggleDropdown"
      ></div>

      <div @click="dropdownClicked" class="form-control-multi-select">
        <span v-if="!showDropdown && activeOptions.length === 0" class="placeholder">
          {{ computedPlaceholder }}
        </span>

        <span
          v-if="!firefoxBrowser && isEdit"
          @click.stop="copyTags(activeOptions)"
          class="d-flex justify-content-end mr-4 text-muted copy-btn"
        >
          <i class="uil uil-copy"></i>
        </span>

        <template v-if="activeOptions.length > 0">
          <div class="options d-block">
            <span
              v-for="(option, key) in activeOptions"
              :key="key"
              :id="option.id"
              v-bind:class="{
                'disabled-option': option.active == 0,
                'bg-azure text-white': extraOptionPropertyIsActive(option[trackByOption]),
              }"
            >
              {{ $prettyLabels(Object.hasOwn(option, selectLabel) ? option[selectLabel] : option) }}
              <i
                aria-hidden="true"
                tabindex="1"
                class="close cursor-pointer"
                :style="readOnly ? 'display: none' : ''"
                @click="onChange(option, (fromRemove = true))"
              ></i>
            </span>
          </div>
        </template>

        <input
          type="text"
          :placeholder="computedPlaceholder"
          class="w-100"
          :class="{ 'd-none': !showDropdown }"
          ref="dropdownInput"
          v-model="search"
          v-debounce:200ms="valueChanged"
          @paste.prevent="onPasteEvent"
          @keydown.up.exact.prevent="focusPrevious(true)"
          @keydown.down.exact.prevent="focusNext(true)"
          @keyup.esc.exact.prevent="hideDropdown()"
          @keypress.enter.exact.prevent="selectItem()"
        />

        <slot
          v-if="showMissingOptionLinkOnSearch"
          name="missingOptionLink"
          :showDropdown="showDropdown"
          :search="search"
        ></slot>
      </div>

      <ul class="dropdown-menu" :class="{ show: showDropdown }" v-if="!showMissingOption">
        <li v-if="loading && !customOptions">Loading ...</li>
        <li @click="$emit('input', null)" v-if="showAllOption">All</li>
        <li
          v-for="(option, key) in getOptionItems"
          v-bind:value="option instanceof Object ? option.id : option"
          v-bind:key="key"
          v-bind:class="{
            active: activeOptions.find((item) => item.id == option.id),
            'disabled-option': option.active == 0,
            'active-item':
              pointer === (option instanceof Object ? option.id : option) &&
              !activeOptions.find((item) => item.id == option.id) &&
              !showExtraOptionProperty,
            'active-item-remove':
              pointer === (option instanceof Object ? option.id : option) &&
              activeOptions.find((item) => item.id == option.id) &&
              !showExtraOptionProperty,
            'justify-content-start align-items-center': resolveExtraOptionLabels(option),
            'justify-content-between': !resolveExtraOptionLabels(option),
          }"
          class="text-truncate d-flex"
          @click="onChange(option)"
          @mouseover="changePointer(key)"
        >
          <template v-if="stylizedExtraLables">
            {{ formattedLabel(option) }}
            <span :class="[badgeClass]">
              {{ resolveExtraOptionLabels(option) }}
            </span>
          </template>
          <template v-else>
            {{ resolveExtraOptionLabels(option) ? resolveExtraOptionLabels(option) + ' > ' : '' }}
            {{ formattedLabel(option) }}
          </template>
          <span
            v-if="pointer !== option.id && !showExtraOptionProperty"
            class="d-flex w-100 justify-content-end align-items-end"
          >
            {{ activeOptions.find((item) => item.id == option.id) ? 'Selected' : '' }}
          </span>

          <slot
            v-if="showExtraOptionProperty"
            name="extraOptionBadge"
            :option="option"
            :index="focusedIndex"
          ></slot>
        </li>

        <li v-if="getOptionItems.length === 0 && !loading">No matching records</li>
      </ul>
    </div>

    <div v-else class="form-control text-muted">
      <template v-if="loading">
        <span>Loading ...</span>
      </template>

      <template v-else>
        <span>No {{ noOptionsPlaceholder }} available</span>

        <span v-if="noOptionsLink">
          , click
          <a v-if="noOptionsLink" :href="noOptionsLink" target="blank"> here </a>
          to add one
        </span>
      </template>
    </div>

    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>

    <small v-if="limitReached" class="form-text text-muted text-red">
      Maximum selected options ({{ max }}) is reached.
    </small>
  </div>
</template>
<script>
import GeneralService from '../../services/GeneralService'
import QuickAddModal from './quick-add-modal.vue'
export default {
  components: { QuickAddModal },
  props: {
    field: {
      required: true,
      type: String,
    },
    manualOptions: {
      type: Array,
      default: () => [],
    },
    selectLabel: {
      type: String,
      default: () => 'name',
    },
    trackByOption: {
      type: String,
      default: () => 'id',
    },
    label: {
      type: String,
    },
    value: {
      default: () => null,
    },
    path: {
      default: () => null,
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select one or multiple options',
    },
    loadOnMount: {
      // load options on mount
      default: () => true,
      type: Boolean,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    notifiedByField: {
      type: Array,
      default: () => null,
    },
    type: {
      type: String,
      default: 'list-many',
    },
    quickAdd: {
      type: Object,
      default: () => null,
    },
    wrapperClass: {
      default: () => 'form-group',
    },
    helpText: {
      type: String,
    },
    customOptions: {
      default: () => null,
    },
    noOptionsPlaceholder: {
      type: String,
      default: () => 'options',
    },
    noOptionsLink: {
      type: String,
    },
    showAllOption: {
      type: Boolean,
      default: () => false,
    },
    max: {
      type: Number,
      required: false,
      default: () => 0,
    },
    validateChanges: {
      type: Boolean,
      default: () => false,
    },
    readOnly: {
      default: () => false,
    },
    disableCopy: {
      type: Boolean,
      default: () => false,
    },
    isDisabled: {
      type: Boolean,
      default: () => false,
    },
    parameters: {
      type: Object,
      required: false,
      default: () => {},
    },
    showMissingOptionLinkOnSearch: {
      type: Boolean,
      default: () => false,
    },
    showExtraOptionProperty: {
      type: Boolean,
      default: () => false,
    },
    extraOptionPropertyIsActive: {
      type: Function,
      default: () => false,
    },
    extraOptionProperty: {
      type: String,
      default: () => null,
    },
    extraOptionLabelKey: {
      type: [String, Array],
      default: () => null,
    },
    stylizedExtraLables: {
      type: Boolean,
      default: () => false,
    },
    isSitesData: {
      type: Boolean,
      default: () => false,
    },
    searchBy: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      data: this.value ? (this.value instanceof Array ? this.value : [this.value]) : [],
      options: [],
      loading: true,
      optionItems: [],
      activeOptionLabel: '',
      showDropdown: false,
      activeOptions: this.value ? (this.value instanceof Array ? this.value : [this.value]) : [],
      search: '',
      selected: null,
      pointer: 0,
      focusedIndex: 0,
      selectedPath: null,
      selectedValue: null,
      firefoxBrowser: false,
      isEdit: false,
      showQuickAddModal: false,
    }
  },
  computed: {
    badgeClass() {
      if (this.isSitesData) {
        return 'badge bg-info ml-2'
      } else {
        return 'badge bg-primary ml-2'
      }
    },
    showMissingOption() {
      return this.showMissingOptionLinkOnSearch && this.getOptionItems.length === 0 && !this.loading
    },
    getOptionItems() {
      if (!this.optionItems) {
        return []
      }

      const searchLower = this.search.toLowerCase()

      return this.optionItems
        .filter((item) => {
          const option =
            item instanceof Object && this.selectLabel in item
              ? item[this.selectLabel].toString().toLowerCase()
              : item.toString().toLowerCase()
          return option.includes(searchLower)
        })
        .sort((a, b) => {
          const getOptionValue = (item) => {
            return item instanceof Object && this.selectLabel in item
              ? item[this.selectLabel].toString().toLowerCase()
              : item.toString().toLowerCase()
          }

          const optionA = getOptionValue(a)
          const optionB = getOptionValue(b)

          const startsWithA = optionA.startsWith(searchLower)
          const startsWithB = optionB.startsWith(searchLower)

          if (startsWithA && !startsWithB) {
            return -1
          } else if (!startsWithA && startsWithB) {
            return 1
          } else {
            return 0
          }
        })
    },
    computedPlaceholder() {
      if (!this.notifyFieldsReady() && Array.isArray(this.notifiedByField) && !this.placeholder) {
        const fieldWord = this.notifiedByField.length > 1 ? 'fields' : 'field'
        return `Select required ${fieldWord} first (${this.notifiedByField
          .map((item) => item.label.toLowerCase())
          .join(', ')})`
      } else {
        return this.placeholder
      }
    },
    limitReached() {
      if (this.max !== 0) {
        return this.activeOptions.length == this.max
      }
      return false
    },
  },
  mounted() {
    if (!this.value) {
      this.activeOptions = []
    }
  },
  created() {
    this.isEdit =
      (this.$route?.params && this.$route.params.id) || (this.$route && this.$route.path === '/users')
    if (this.customOptions) {
      this.options = this.customOptions
      if (this.value || this.activeOptions.length > 0) {
        this.setOptionsFromValues(this.customOptions)
      }
    } else if ((this.path && this.loadOnMount) || (this.value && this.value.length > 0)) {
      this.getOptions()
    }
    window.addEventListener('click', (e) => {
      if (this.$el.querySelector('.dropdown') && !this.$el.querySelector('.dropdown').contains(e.target)) {
        if (!e.target.classList.contains('close')) {
          this.hideDropdown()
        }
      }
    })

    this.firefoxBrowser = navigator.userAgent.indexOf('Firefox') != -1
  },
  watch: {
    customOptions() {
      this.options = this.customOptions
    },
    options() {
      this.optionItems = this.options
    },
    activeOptionLabel() {
      this.activeOptionLabel
        ? (this.optionItems = this.options.filter((item) => {
            if (item instanceof Object) {
              return item[this.selectLabel].toLowerCase().includes(this.activeOptionLabel.toLowerCase())
            }
            return item.toLowerCase().includes(this.activeOptionLabel.toLowerCase())
          }))
        : (this.optionItems = this.options)
    },
    search() {
      this.focusedIndex = 0
      this.focusItem()
    },
    activeOptions() {
      if (this.$refs.validator) {
        this.$refs.validator.validate()
      }
    },
  },
  methods: {
    openQuickAddModal() {
      this.showQuickAddModal = true
      this.$nextTick(() => {
        this.$refs?.quickAddModal.show()
      })
    },
    formattedLabel(option) {
      if (!option) {
        return ''
      }
      return this.$prettyLabels(Object.hasOwn(option, this.selectLabel) ? option[this.selectLabel] : option)
    },
    toggleDropdown() {
      if (this.showDropdown) {
        this.hideDropdown()
      } else {
        this.displayDropdown()
      }
    },
    async onPasteEvent() {
      try {
        const clipBoard = await navigator.clipboard.readText().then()
        const value = JSON.parse(clipBoard)

        // see if values we want to paste, exist in options
        const foundValues = this.options.filter((obj1) =>
          value.value.some((obj2) => obj1[this.trackBy()] === obj2[this.trackBy()])
        )
        // path.split('?')[0] is used to remove query params from path
        if (!foundValues.length || value.path.split('?')[0] !== this.path.split('?')[0]) {
          this.showErrorMessage('The values you are trying to paste are not available as options.')
          return false
        }

        if (value.path.split('?')[0] === this.path.split('?')[0] && foundValues.length > 0) {
          this.selectedPath = value.path.split('?')[0]
          this.selectedValue = foundValues
        }
      } catch (e) {
        this.showErrorMessage('The values you are trying to paste are not available as options.')
        return false
      }
      if (this.selectedValue?.length) {
        this.activeOptions = this.selectedValue
        this.$emit('sync', this.field, this.activeOptions, this.trackBy())
      }
    },
    copyTags(value) {
      navigator.clipboard.writeText(JSON.stringify({ path: this.path, value }))
      // Page name comes back as ex. "Operators". I'm doing this to have it "operator".
      const pageName = this.$route.matched[0].meta.name
        .toLowerCase()
        .substr(0, this.$route.matched[0].meta.name.length - 1)
      if (this.path !== 'hercules/operators/countries') {
        this.showSuccessMessage(
          `${this.label} copied to clipboard. You can paste this data in a ${this.label} field of another ${pageName}`,
          'Success',
          false,
          '5000'
        )
      } else {
        let secondLabel =
          this.label === 'Restricted countries' ? 'Blacklisted Countries' : 'Restricted countries'
        this.showSuccessMessage(
          `${this.label} copied to clipboard. You can paste this data in the ${this.label} field or a ${secondLabel} field of another ${pageName}`,
          'Success',
          false,
          '5000'
        )
      }
    },
    displayDropdown() {
      // No need to fetch options again if they have already been fetched
      if (this.searchBy && this.getOptionItems.length === 0 && this.options.length === 0) {
        this.loading = true
        this.getOptions().then(() => {
          this.showDropdown = true
          this.focusItem()
          setTimeout(() => {
            this.$refs.dropdownInput.focus()
          }, 500)
        })
      } else {
        this.showDropdown = true
        this.focusItem()
        setTimeout(() => {
          this.$refs.dropdownInput.focus()
        }, 500)
      }
    },
    hideDropdown() {
      this.showDropdown = false
      this.search = ''
      this.focusedIndex = 0
      this.focusItem()
    },
    async selectAll() {
      await (this.path && !this.loadOnMount && this.options.length === 0 && this.getOptions())
      this.activeOptions = this.options
      this.$emit('sync', this.field, this.activeOptions, this.trackBy())
    },
    deselectAll() {
      this.activeOptions = []
      this.$emit('sync', this.field, this.activeOptions, this.trackBy())
    },
    dropdownClicked(event) {
      if (
        !event.target.className.includes('close') &&
        this.notifyFieldsReady() &&
        !this.limitReached &&
        !this.readOnly
      ) {
        // Check if options need to be fetched before showing the dropdown
        if (this.path && !this.loadOnMount && this.options.length === 0) {
          this.getOptions().then(() => {
            this.displayDropdown()
          })
        } else {
          this.displayDropdown()
        }
      }
    },

    async onChange(option, fromRemove) {
      // I need to know when onChange is called from remove event to set the initialState of the dropdownInput to track unsaved changes
      if (fromRemove) {
        setTimeout(() => this.onChange(option), 100)
        return
      }
      if (this.activeOptions.find((item) => item.id == option.id)) {
        let index = this.activeOptions.indexOf(option)
        this.activeOptions.splice(index, 1)
      } else if (this.activeOptions.length === this.max - 1) {
        this.activeOptions.push(option)
        this.$refs.dropdownInput.classList.add('disabled')
        this.showDropdown = false
      } else {
        this.activeOptions.push(option)
        this.search = ''
      }
      if (this.searchBy) {
        this.getOptions()
      }
      this.$emit('sync', this.field, this.activeOptions, this.trackBy())
      if (this.showDropdown) {
        this.$refs.dropdownInput.focus()
      }
    },
    focusNext() {
      this.focusedIndex = (this.focusedIndex + 1) % this.getOptionItems.length
      this.focusItem()
    },
    focusPrevious() {
      this.focusedIndex = (this.focusedIndex - 1 + this.getOptionItems.length) % this.getOptionItems.length
      this.focusItem()
    },
    focusItem() {
      this.pointer = this.getOptionItems[this.focusedIndex]?.id || null
    },
    selectItem() {
      this.onChange(this.getOptionItems[this.focusedIndex])
    },
    changePointer(index) {
      this.focusedIndex = index
      this.focusItem()
    },
    getOptions(data = {}) {
      if (!this.notifyFieldsReady()) {
        this.loading = false
        return
      }

      data = {
        ...data,
        ...(this.parameters ? this.parameters : {}),
        ...(this.searchBy && this.search.length ? { [this.searchBy]: this.search } : {}),
      }

      if (Array.isArray(this.notifiedByField)) {
        this.notifiedByField.forEach((field) => {
          // filter multiple values using a comma instead of array annotation ?field[]=1&field[]=2
          if (field && field.multi_filter_separated_by_comma) {
            data[field.request_field_name] = this.$parent.object[field.field].join(',')
          } else if (field.request_field_name) {
            data[field.request_field_name] = this.$parent.object[field.field]
          } else {
            data[field.field] = this.$parent.object[field.field]
          }
        })
      }
      return new Promise((resolve) => {
        if (this.path) {
          GeneralService.fetchItems(this.path, data)
            .then((response) => {
              this.options =
                response.data.result.length > 0 &&
                response.data.result.map((item) => {
                  if (this.extraOptionProperty !== null) {
                    item[this.extraOptionProperty] = false
                  }
                  return item
                })
              this.loading = false

              if (this.value || this.activeOptions.length > 0) {
                this.setOptionsFromValues(response.data.result)
              }
              this.$emit('sync', this.field, this.activeOptions, this.trackBy())
              resolve()
            })
            .catch(() => this.showErrorMessage(`Failed to fetch options for ${this.label}`))
        } else {
          if (!this.manualOptions) {
            // eslint-disable-next-line
            console.error('base-multiselect error: Please provide an URL or an array with manualOptions')
          } else {
            this.options = this.manualOptions
            resolve()
          }
        }
      })
    },
    setOptionsFromValues(data) {
      if (this.searchBy) {
        return
      }

      const getElement = (element) => {
        return element instanceof Object ? element[this.trackByOption] : element
      }

      let selectedElements = []

      // Populate selectedElements from activeOptions
      this.activeOptions.forEach((element) => {
        selectedElements.push(getElement(element))
      })

      if (Array.isArray(this.value)) {
        this.value.forEach((element) => {
          const elem = getElement(element)
          if (!selectedElements.includes(elem)) {
            selectedElements.push(elem)
          }
        })
      } else if (this.value != null) {
        const elem = getElement(this.value)
        if (!selectedElements.includes(elem)) {
          selectedElements.push(elem)
        }
      }

      // Filter data to update activeOptions
      this.activeOptions = data.filter((item) => {
        const itemElement = getElement(item)
        if (selectedElements.includes(itemElement)) {
          if (this.extraOptionProperty !== null) {
            const foundItem = selectedElements.find((s) => {
              const elem = getElement(s)
              return elem === itemElement
            })
            if (foundItem) {
              item[this.extraOptionProperty] = foundItem[this.extraOptionProperty] > 0 ? true : false
            }
          }
          return true
        }
        return false
      })

      // Sort for vee-validation to be correct
      this.activeOptions.sort((a, b) => {
        return selectedElements.indexOf(getElement(a)) - selectedElements.indexOf(getElement(b))
      })
    },
    trackBy() {
      return this.manualOptions && this.manualOptions.length > 0
        ? this.trackByOption
          ? this.trackByOption
          : ''
        : this.trackByOption
    },
    notifyFieldsReady() {
      if (Array.isArray(this.notifiedByField) && this.$parent.$parent.object) {
        return (
          this.notifiedByField.filter((item) => {
            return !this.$parent.$parent.object[item.field]
          }).length === 0
        )
      }
      return true
    },
    resolve(path, obj) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    resolveExtraOptionLabels(option) {
      if (!this.extraOptionLabelKey) return ''
      if (Array.isArray(this.extraOptionLabelKey)) {
        const labels = this.extraOptionLabelKey
          .map((key) => {
            const value = this.resolve(key, option)
            return Array.isArray(value) ? value.map((item) => item.name).join(', ') : value
          })
          .filter(Boolean)
        return labels.length ? labels.join(', ') : ''
      } else {
        const value = this.resolve(this.extraOptionLabelKey, option)
        if (Array.isArray(value)) {
          return value.length ? value.map((item) => item.name).join(', ') : ''
        }
        return value ? value.toUpperCase() : ''
      }
    },
    valueChanged() {
      if (this.searchBy) {
        this.getOptions()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.quick-add-btn {
  padding: 4px 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
  background-color: #f1f2f9;
  box-shadow: none;
  color: #1642a8;
  border-color: transparent;
}
.extra-option {
  border: 1px solid red;
}
.dropdown {
  cursor: pointer;
}

.dropdown.disabled {
  cursor: not-allowed;
}

.dropdown .dropdown-menu {
  width: 100%;
  transition: none !important;
  transition-delay: unset !important;
}

.dropdown .dropdown-menu.show {
  transform: scaleY(1);
}

.dropdown .dropdown-menu li {
  text-transform: none;
}

.dropdown .dropdown-menu.show li.active {
  background-color: #f3f3f3;
}

.dropdown .dropdown-menu.show li:hover {
  background-color: #f6f6fb;
}

.dropdown .dropdown-menu li span {
  opacity: 0.5;
}

.form-control-multi-select {
  background-color: #f1f2f9;
  border-color: #f1f2f9;
  padding: 11px 18px 11px 8px;
  font-weight: 500;
  min-height: 44px;
  border-radius: 4px;
}

.dropdown.disabled .form-control-multi-select {
  background-color: #e3e6ef;
  border-color: #e3e6ef;
}

.form-control-multi-select:hover {
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
}

.form-control-multi-select span.placeholder {
  margin-left: 8px;
  pointer-events: none;
  opacity: 0.5;
}

.form-control-multi-select .options span {
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background-color: white;
  margin: 5px 10px;
  margin-left: 0;
  color: #47596d;
  line-height: 30px;
  padding: 1px 16px;
}

.form-control-multi-select .options span i {
  position: unset;
  height: 20px;
  margin-left: 5px;
}

.form-control-multi-select input {
  display: inline-block;
  border: none;
  background: transparent;
  opacity: 0.7;
  padding-left: 8px;
}

.multiselect__arrow {
  position: absolute;
  width: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
  display: flex;
}

.multiselect__arrow::before {
  display: inline-block;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
  transition: transform 0.2s ease;
  margin: auto;
}

.multiselect__arrow.active::before {
  transform: rotate(180deg);
}

.dropdown .dropdown-menu.show li.active-item,
.dropdown .dropdown-menu.show li.active-item-remove {
  background-color: #f6f6fb;
}

.active-item::after,
.active-item-remove:after {
  position: absolute;
  right: 10px;
}

.active-item::after {
  content: 'Press enter to select';
}

.active-item-remove::after {
  content: 'Press enter to remove';
}

.disabled-option {
  color: #ced0dd !important;
}

.disabled-option span {
  color: #6e6e84;
}

.copy-btn {
  width: fit-content;
  float: right;
  padding: 2px;
  &:active {
    background-color: white;
    border-radius: 4px;
  }
}

.badge-opacity {
  opacity: 1 !important;
}

.bg-azure {
  .close::before {
    color: white !important;
  }
}
</style>
