<template>
  <div>
    <div class="mb-2">
      <colaborative-edit-notification
        v-if="showColaborativeNotification"
        :route_path="$route.fullPath"
        :username="username"
      />
    </div>
    <div class="row">
      <div class="col" v-if="template">
        <div class="card">
          <div class="card-header">
            <h3>Search Engine Optimization</h3>
            <hr class="hr-fat mb-2" />
            <div class="d-flex justify-content-end">
              <span @click="openModalInformation()" class="btn btn-outline-info btn-md mr-3">
                <i class="uil uil-info-circle"></i>
                Help
              </span>

              <button
                :disabled="showColaborativeNotification"
                class="btn btn-primary"
                v-bind:class="{ loading: isLoading }"
                @click="update"
              >
                <i class="uil uil-check mr-2"></i>
                Save
              </button>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col">
                <base-input
                  label="Default meta title"
                  v-model="template.meta_title"
                  :help-text="eventMetaTitleHelpText"
                />
              </div>

              <div class="col">
                <base-input label="Character count" v-model="charCountTitle" :disabled="true" />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <base-textarea label="Default meta description" v-model="template.meta_description" />
              </div>

              <div class="col">
                <base-input label="Character count" v-model="charCountDescription" :disabled="true" />
              </div>
            </div>

            <div class="row" v-if="template.meta_title">
              <div class="col">
                <label>Preview snippet</label>

                <div class="card">
                  <div class="card-body border p-3">
                    <h4 :style="{ color: '#12c' }" class="mb-0">{{ template.meta_title }}</h4>

                    <p :style="{ color: '#093' }" class="mb-0">www.google.com</p>

                    <p>{{ template.meta_description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <information-modal ref="informationModal" :id="confluenceId" />
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseTextarea from '@atoms/fields/base-textarea.vue'
import TemplatesService from '@services/TemplatesService'
import confluenceIds from '@constants/confluence-ids'
import InformationModal from '@molecules/cms/modals/information-modal'
import ColaborativeEditNotification from '@molecules/colaborative-edit-notification'

export default {
  components: {
    BaseInput,
    BaseTextarea,
    InformationModal,
    ColaborativeEditNotification,
  },
  data() {
    return {
      template: null,
      isLoading: false,
      confluenceId: 0,
      showColaborativeNotification: false,
      modelName: 'sites-templates',
      username: '',
    }
  },
  created() {
    this.confluenceId = confluenceIds.page_types.seo.id

    let pusherChannel = window.pusher.subscribe('updateModelChannel')

    pusherChannel.bind('pusher:subscription_succeeded', function () {})

    pusherChannel.bind('updateModel', (message) => {
      if (
        message.modelName === this.modelName &&
        message.modelId === Number(this.template.id) &&
        message.pusherToken !== this.$store.state.app.uniqueKey
      ) {
        this.showColaborativeNotification = true
        this.username = message.username
      }
    })
  },
  mounted() {
    this.getSiteLanguage()
  },
  computed: {
    eventMetaTitleHelpText() {
      if (this.template.short_name == 'event') {
        return 'Available variables: {home_team}, {away_team}, {sport}, {time} and {date}'
      }
      return ''
    },
    charCountTitle() {
      return this.template.meta_title ? `${this.template.meta_title.length}` : 0
    },
    charCountDescription() {
      return this.template.meta_description ? `${this.template.meta_description.length}` : 0
    },
  },
  methods: {
    openModalInformation() {
      this.$refs.informationModal.show()
    },
    async getSiteLanguage() {
      await TemplatesService.getOne(this.$route.params['id'], { with: 'market,site' })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
            response.data.messages.some((message) => {
              if (message.indexOf('Template not found') >= 0) {
                this.$router.push('/site-templates')
              }
            })
          } else {
            this.template = response.data.result[0]
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.isLoading = false
        })
    },
    async update() {
      if (this.showColaborativeNotification) {
        return
      }
      this.isLoading = true
      delete this.template.enabled_sections
      await TemplatesService.update(this.template)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.template = response.data.result
            this.showSuccessMessage('SEO settings updated')
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>
